import React from 'react'

const SvgFeature1 = props => (
  <svg viewBox='0 0 50 50' {...props}>
    <path fill='#fd5c1f' d='M17 16.45h16v18H17z' />
    <path
      d='M47.92 7.38H45V5.21c0-.46-.5-.84-1.11-.83h-.56c-6.65.01-13.1 1.7-18.33 4.81-5.22-3.11-11.68-4.81-18.33-4.81h-.56C5.5 4.37 5 4.75 5 5.21v2.17H2.1c-.6 0-1.1.49-1.1 1.1V43.3c0 .59.48 1.08 1.08 1.08h15.07c1.78 0 3.54.33 5.19.98l2.18 1.09c.26.12.57.12.83 0l2.18-1.07c1.7-.66 3.5-1 5.32-1H47.9c.6 0 1.1-.49 1.1-1.1V8.45c0-.59-.48-1.07-1.08-1.07zM26 43.28v-31.9c4.82-3.63 10.8-4.91 17-5.03v31.7c-6.1.12-12.02 1.95-17 5.23zM7 6.34c6.2.13 12.17 1.41 17 5.03v31.9c-4.98-3.27-10.9-5.1-17-5.23V6.34zM3 42.38v-32h2v29c0 .55.45 1 1 1h.44c3.35.01 6.66.69 9.74 2H3zm44 0H33.01c3.3-1.31 6.86-1.99 10.45-2h.47c.59 0 1.07-.45 1.07-1v-30h2v33z'
      fill='#2b4b67'
    />
  </svg>
)

export default SvgFeature1
