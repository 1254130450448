import React from 'react'

const SvgProcessEn = props => (
  <svg id='process_en_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 657.15 404.69' xmlSpace='preserve' {...props}>
    <style>
      {
        '.process_en_svg__st0{fill:none}.process_en_svg__st1{fill:#252a3a}.process_en_svg__st2{fill:#6f7c8d}.process_en_svg__st3{font-family:&apos;OpenSans&apos;}.process_en_svg__st4{font-size:12px}.process_en_svg__st5{fill:#fd5c1f}.process_en_svg__st6{enable-background:new}.process_en_svg__st7{font-size:11px}'
      }
    </style>
    <path className='process_en_svg__st0' d='M451.89 225.6h-254' />
    <path className='process_en_svg__st1' d='M197.89 224.6h254v2h-254z' />
    <path className='process_en_svg__st1' d='M451.48 219.6l8 6-8 6M198.48 231.6l-8-6 8-6' />
    <path className='process_en_svg__st0' d='M175.89 108.6h-122' />
    <path className='process_en_svg__st1' d='M53.89 107.6h122v2h-122z' />
    <path className='process_en_svg__st1' d='M175.48 102.6l8 6-8 6M54.48 114.6l-8-6 8-6' />
    <text
      transform='translate(293.942 248.815)'
      className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'
    >
      {'Raysync Protocol'}
    </text>
    <path
      className='process_en_svg__st5'
      d='M280.69 236.09c-1.86 0-3.72.36-5.35.76l1.21.87c-1.63.31-3.3.22-4.22.01v3.57c1.24.33 5.33.09 7.13-.65l-.56-.68c.66-.07 1.11-.08 1.8-.08 1.91 0 3.72.26 5.35.74v-3.79c-1.55-.37-3.5-.75-5.36-.75z'
    />
    <path
      className='process_en_svg__st1'
      d='M286.37 249.92c-1.25.13-2.12.33-3.01.59v1.05c-1.38.31-3.62.62-5.42.5-1.86-.12-4.26-.79-5.29-1.18v-3.77c1.47.78 4.24 1.31 6.53 1.31 1.28 0 2.84-.17 3.76-.36l-.18-.94c1.17-.4 1.9-.74 3.61-.91v3.71zM284.03 241.36c-1.3 0-3.2.3-3.74.45l.62 1c-1.4.35-2.8.35-3.82.35-2.4 0-4.45-.51-6.57-1.32v3.78c1.63.55 3.73.99 5.71.99s4.16-.27 6.03-.92l-.17-.46c.65-.08 1.2-.12 1.91-.12 1.33 0 2.46.05 3.85.38v-3.79c-.77-.17-2.52-.34-3.82-.34z'
    />
    <text transform='translate(91.104 98.091)' className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
      {'Build TCP'}
    </text>
    <path className='process_en_svg__st0' d='M175.89 182.6h-122' />
    <path className='process_en_svg__st1' d='M53.89 181.6h122v2h-122z' />
    <path className='process_en_svg__st1' d='M175.48 176.6l8 6-8 6M54.48 188.6l-8-6 8-6' />
    <path className='process_en_svg__st0' d='M175.89 355.6h-122' />
    <path className='process_en_svg__st1' d='M53.89 354.6h122v2h-122z' />
    <path className='process_en_svg__st1' d='M175.48 349.6l8 6-8 6M54.48 361.6l-8-6 8-6' />
    <text transform='translate(90.043 345.091)' className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
      {'APP Data'}
    </text>
    <text
      transform='translate(513.747 345.092)'
      className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'
    >
      {'APP Data'}
    </text>
    <path className='process_en_svg__st0' d='M187.89 73.15v327' />
    <path className='process_en_svg__st1' d='M186.89 73.15h2v327h-2z' />
    <path className='process_en_svg__st0' d='M42.89 73.15v327' />
    <path className='process_en_svg__st1' d='M41.89 73.15h2v327h-2z' />
    <path className='process_en_svg__st0' d='M593.89 275.6h-122' />
    <path className='process_en_svg__st1' d='M471.89 274.6h122v2h-122z' />
    <path className='process_en_svg__st1' d='M593.48 269.6l8 6-8 6' />
    <g>
      <path className='process_en_svg__st1' d='M472.48 281.6l-8-6 8-6' />
    </g>
    <text transform='translate(197.89 196.946)' className='process_en_svg__st6'>
      <tspan x={0} y={0} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st7'>
        {'Get the IP address and port that the user actually'}
      </tspan>
      <tspan x={0} y={13.2} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st7'>
        {' wants to connect through the proxy protocol'}
      </tspan>
    </text>
    <g>
      <path className='process_en_svg__st0' d='M450.89 332.6h-254' />
      <path className='process_en_svg__st5' d='M196.89 331.6h254v2h-254z' />
      <path className='process_en_svg__st5' d='M450.48 326.6l8 6-8 6' />
      <g>
        <path className='process_en_svg__st5' d='M197.48 338.6l-8-6 8-6' />
      </g>
    </g>
    <text transform='translate(206.403 307.091)' className='process_en_svg__st6'>
      <tspan x={0} y={0} className='process_en_svg__st5 process_en_svg__st3 process_en_svg__st7'>
        {'Complete data transmission between client '}
      </tspan>
      <tspan x={0} y={16} className='process_en_svg__st5 process_en_svg__st3 process_en_svg__st7'>
        {'and server through Raysync transmission'}
      </tspan>
    </text>
    <g>
      <path className='process_en_svg__st0' d='M593.89 355.6h-122' />
      <path className='process_en_svg__st1' d='M471.89 354.6h122v2h-122z' />
      <path className='process_en_svg__st1' d='M593.48 349.6l8 6-8 6' />
      <g>
        <path className='process_en_svg__st1' d='M472.48 361.6l-8-6 8-6' />
      </g>
    </g>
    <g>
      <path className='process_en_svg__st0' d='M605.89 73.15v327' />
      <path className='process_en_svg__st1' d='M604.89 73.15h2v327h-2z' />
    </g>
    <g>
      <path className='process_en_svg__st0' d='M460.89 73.15v327' />
      <path className='process_en_svg__st1' d='M459.89 73.15h2v327h-2z' />
    </g>
    <text transform='translate(560.614 60.571)' className='process_en_svg__st1 process_en_svg__st3 process_en_svg__st4'>
      {'User APP Server'}
    </text>
    <text transform='translate(9.918 60.571)' className='process_en_svg__st1 process_en_svg__st3 process_en_svg__st4'>
      {'User APP'}
    </text>
    <g>
      <path
        className='process_en_svg__st1'
        d='M49.24 15.33v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21V14c-1.97 1.51-5.92 2.31-9.7 2.31-2.6 0-5.03-.34-6.98-.98zM46.52 5.55c.03 1.51 4.16 3.2 9.7 3.2 5.55 0 9.7-1.7 9.7-3.21s-4.15-3.21-9.7-3.21c-5.54 0-9.69 1.69-9.7 3.21v.01zM25.92 27.63v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.87-.89-11.02-2.56z'
      />
      <path
        className='process_en_svg__st1'
        d='M25.92 20.92v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.87-.89-11.02-2.56zM49.24 21.6v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31-2.6 0-5.03-.35-6.98-.98z'
      />
      <path
        className='process_en_svg__st1'
        d='M25.92 14.2v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49V14.2c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.87-.89-11.02-2.56zM56.22 10.04c-3.78 0-7.72-.8-9.7-2.31V8.9c1.66.77 2.72 1.8 2.72 3.07v1.99c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21V7.73c-1.97 1.51-5.92 2.31-9.7 2.31zM49.24 27.87v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.04-.36-6.98-.99zM25.92 34.35v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.87-.89-11.02-2.56z'
      />
      <path
        className='process_en_svg__st1'
        d='M36.93 8.49c-6.3 0-11.02 1.84-11.02 3.49s4.71 3.49 11.02 3.49c6.3 0 11.01-1.84 11.01-3.49.01-1.65-4.7-3.49-11.01-3.49z'
      />
    </g>
    <text transform='translate(142.852 60.571)' className='process_en_svg__st5 process_en_svg__st3 process_en_svg__st4'>
      {'Raysync Proxy Client'}
    </text>
    <g>
      <path
        className='process_en_svg__st5'
        d='M196.21 15.33v4.9c1.86.67 4.37 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21V14c-1.97 1.51-5.92 2.31-9.7 2.31-2.59 0-5.03-.34-6.98-.98zM193.49 5.55c.03 1.51 4.16 3.2 9.7 3.2 5.55 0 9.7-1.7 9.7-3.21s-4.15-3.21-9.7-3.21c-5.54 0-9.69 1.69-9.7 3.21v.01zM172.89 27.63v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56s-8.86-.89-11.02-2.56z'
      />
      <path
        className='process_en_svg__st5'
        d='M172.89 20.92v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.33-.01-8.86-.89-11.02-2.56zM196.21 21.6v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31-2.59 0-5.03-.35-6.98-.98z'
      />
      <path
        className='process_en_svg__st5'
        d='M172.89 14.2v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49V14.2c-2.16 1.67-6.68 2.56-11.01 2.56s-8.86-.89-11.02-2.56zM203.19 10.04c-3.78 0-7.72-.8-9.7-2.31V8.9c1.66.77 2.72 1.8 2.72 3.07v1.99c1.86.67 4.37 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21V7.73c-1.97 1.51-5.91 2.31-9.7 2.31zM196.21 27.87v4.9c1.86.67 4.37 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.59-.01-5.03-.36-6.98-.99zM172.89 34.35v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.33-.01-8.86-.89-11.02-2.56z'
      />
      <path
        className='process_en_svg__st5'
        d='M183.91 8.49c-6.3 0-11.02 1.84-11.02 3.49s4.71 3.49 11.02 3.49c6.3 0 11.01-1.84 11.01-3.49s-4.71-3.49-11.01-3.49z'
      />
    </g>
    <g>
      <path
        className='process_en_svg__st1'
        d='M614.39 38.32c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.34.33-.78.49-1.24.47m-5.7 0c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.33-.78.49-1.24.47zM588.37 35h10v3.32h-10V35zm-2.7-4h-3v9.32a2.037 2.037 0 002.15 2h35.62c.55.02 1.09-.18 1.49-.55.4-.38.64-.89.66-1.45V31h-36.92zm28.79-21.32a1.83 1.83 0 01-1.95-1.6c.04-.93.77-1.67 1.7-1.72.94-.02 1.73.67 1.85 1.6-.01.89-.7 1.64-1.6 1.72zm-5.7 0a1.97 1.97 0 01-1.65-.8c-.32-.51-.32-1.16 0-1.67.41-.65 1.18-.97 1.93-.81.75.16 1.32.77 1.42 1.53.03.46-.14.92-.46 1.25-.32.33-.77.51-1.24.5zm-20.39-3.35h10v3.35h-10V6.33zm31.22-4h-33.87c-1.6-.04-2.93 1.2-3 2.8v8.55h39.99V5.13a3.023 3.023 0 00-3.12-2.8zM588.37 21h10v3.32h-10V21zm20.24 0c.7-.01 1.34.38 1.65 1 .29.61.15 1.34-.35 1.8-.53.46-1.26.61-1.92.4-.65-.24-1.1-.83-1.15-1.52.06-.94.84-1.67 1.77-1.68zm5.7 0c.94-.02 1.73.67 1.85 1.6-.04.93-.77 1.67-1.7 1.72-.94.02-1.73-.67-1.85-1.6.02-.93.77-1.69 1.7-1.72zm-31.64 7.32h39.84V17h-39.99l.15 11.32z'
      />
    </g>
    <text transform='translate(414.852 60.571)' className='process_en_svg__st5 process_en_svg__st3 process_en_svg__st4'>
      {'Raysync Proxy Server'}
    </text>
    <g>
      <path
        className='process_en_svg__st5'
        d='M472.06 38.32c-.91-.05-1.61-.83-1.57-1.74.04-.91.79-1.63 1.7-1.61s1.64.76 1.64 1.67c-.01.46-.2.9-.53 1.21-.33.33-.78.49-1.24.47m-5.7 0c-.91-.05-1.61-.83-1.57-1.74.04-.91.79-1.63 1.7-1.61s1.64.76 1.64 1.67c-.01.46-.2.9-.53 1.21-.33.33-.78.49-1.24.47zM446.04 35h10v3.32h-10V35zm-2.7-4h-3v9.32a2.037 2.037 0 002.15 2h35.62c.55.02 1.09-.18 1.49-.55.4-.38.64-.89.66-1.45V31h-36.92zm28.79-21.32a1.83 1.83 0 01-1.95-1.6c.04-.93.77-1.67 1.7-1.72.94-.02 1.73.67 1.85 1.6-.01.89-.7 1.64-1.6 1.72zm-5.69 0a1.97 1.97 0 01-1.65-.8c-.32-.51-.32-1.16 0-1.67.41-.65 1.18-.97 1.93-.81.75.16 1.32.77 1.42 1.53.03.46-.14.92-.46 1.25-.33.33-.78.51-1.24.5zm-20.4-3.35h10v3.35h-10V6.33zm31.22-4h-33.87c-1.6-.04-2.93 1.2-3 2.8v8.55h39.99V5.13a3.023 3.023 0 00-3.12-2.8zM446.04 21h10v3.32h-10V21zm20.25 0c.7-.01 1.34.38 1.65 1 .29.61.15 1.34-.35 1.8-.53.46-1.26.61-1.92.4-.65-.24-1.1-.83-1.15-1.52.06-.94.83-1.67 1.77-1.68zm5.69 0c.94-.02 1.73.67 1.85 1.6-.04.93-.77 1.67-1.7 1.72-.94.02-1.73-.67-1.85-1.6.02-.93.77-1.69 1.7-1.72zm-31.64 7.32h39.84V17h-39.99l.15 11.32z'
      />
    </g>
    <path className='process_en_svg__st0' d='M114.48 168.81h2.37-2.37z' />
    <text transform='translate(77.74 154.185)'>
      <tspan x={0} y={0} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
        {'Http/Socks'}
      </tspan>
      <tspan x={0} y={16.8} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
        {'Proxy Protocol'}
      </tspan>
    </text>
    <g>
      <text transform='translate(477.622 226.6)'>
        <tspan x={0} y={0} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
          {'Connect user target'}
        </tspan>
        <tspan x={4.02} y={14.4} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
          {' server IP address '}
        </tspan>
        <tspan x={3.03} y={28.8} className='process_en_svg__st2 process_en_svg__st3 process_en_svg__st4'>
          {'and port Build TCP'}
        </tspan>
      </text>
    </g>
  </svg>
)

export default SvgProcessEn
