import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__a1a3b839-6127-4e6f-855b-e77161d02dee'
    x={0}
    y={0}
    viewBox='0 0 480 360'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.banner_svg__st0{opacity:.5}.banner_svg__st1{fill:#6f8b99}.banner_svg__st4{fill:#d9eaff}.banner_svg__st40{fill:#fff}'
      }
    </style>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M433.3 313.97c-.24 0-.49-.06-.7-.19l-29.85-17.21a.689.689 0 01-.35-.56v-.06c0-.24.13-.47.35-.59l22.71-13.09c.44-.25.97-.25 1.41 0l29.85 17.21c.15.09.26.23.32.4v.23c0 .24-.14.47-.35.59l-22.66 13.08c-.24.14-.48.2-.73.19zm-7.2-31.43c-.17 0-.33.04-.49.13l-22.66 13.09c-.07.04-.13.12-.13.2 0 .1.04.19.13.23l29.85 17.21c.3.17.68.17.97 0l22.65-13.09c.07-.04.12-.1.13-.17a.24.24 0 00-.13-.22l-29.85-17.22a.951.951 0 00-.46-.14l-.01-.02z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={74.624}
      y1={-210.938}
      x2={122.928}
      y2={-218.778}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M95.81 73.22l-3.37-1.94a.446.446 0 00-.42 0l-4.44 2.57-6.1.46v3.15c0 .05.03.11.08.14l7.94 4.59c.14.07.29.07.42 0l10.46-6.03a.16.16 0 00.08-.14v-3.15l-4.65.35z'
      fill='url(#banner_svg__SVGID_1_)'
    />
    <linearGradient
      id='banner_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={66.45}
      y1={-210.49}
      x2={105.034}
      y2={-220.337}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M81.49 74.3c0 .05.04.11.08.14l7.94 4.58c.14.07.29.07.42 0l10.46-6.03a.16.16 0 00.08-.14c0-.05-.04-.11-.08-.14l-7.95-4.59a.397.397 0 00-.42 0l-10.46 6.03c-.05.03-.09.09-.07.15-.01 0 0 0 0 0z'
      fill='url(#banner_svg__SVGID_2_)'
    />
    <path
      className='banner_svg__st4'
      d='M85.58 73.76c.01.03.02.05.05.07l4.5 2.57c.07.04.16.04.23 0l5.88-3.38c.01-.03.01-.04 0-.07a.11.11 0 000-.08l-4.5-2.57a.195.195 0 00-.23 0l-5.88 3.38c-.03.02-.05.05-.05.08z'
    />
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={90.942}
      y1={-270.077}
      x2={90.339}
      y2={-76.573}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M96.2 10.93l-4.5-2.57a.195.195 0 00-.23 0l-5.88 3.38c-.03.02-.05.05-.05.08 0 .34-.27 61.76 0 61.91.01.03.02.05.05.07l4.5 2.57c.07.04.16.04.23 0l5.88-3.38c.01-.03.01-.05 0-.07V11.01c.01-.02.01-.05 0-.08z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <linearGradient
      id='banner_svg__SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1={91.198}
      y1={-271.777}
      x2={90.1}
      y2={-171.641}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M93.34 15.73l-2.05-1.19a.108.108 0 00-.11 0l-2.7 1.56c-.01.02-.01.03 0 .05v57.38l2.06 1.19c.04.02.07.02.11 0l2.7-1.56-.01-57.43z'
      opacity={0.5}
      fill='url(#banner_svg__SVGID_4_)'
    />
    <linearGradient
      id='banner_svg__SVGID_5_'
      gradientUnits='userSpaceOnUse'
      x1={446.214}
      y1={-28.178}
      x2={494.518}
      y2={-36.017}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M467.4 255.97l-3.36-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.09.46v3.15c0 .05.03.11.08.14l7.95 4.59c.13.07.29.07.41 0L472 258.9c.04-.03.08-.08.08-.14v-3.16l-4.68.37z'
      fill='url(#banner_svg__SVGID_5_)'
    />
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={438.043}
      y1={-27.722}
      x2={476.636}
      y2={-37.578}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M453.08 257.06c0 .05.04.11.08.14l7.95 4.58c.13.07.29.07.41 0l10.47-6.03c.05-.03.08-.08.08-.13s-.03-.11-.08-.13l-7.95-4.58a.38.38 0 00-.41 0l-10.47 6.03c-.06.01-.08.06-.08.12z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <path
      className='banner_svg__st4'
      d='M457.18 256.51c0 .03.02.05.04.07l4.46 2.57c.07.05.17.05.24 0l5.87-3.38c.03-.02.04-.04.04-.07 0-.04-.02-.06-.04-.08l-4.46-2.57a.235.235 0 00-.23 0l-5.88 3.42c-.03.02-.04.05-.04.09v-.05z'
    />
    <linearGradient
      id='banner_svg__SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1={462.572}
      y1={-87.319}
      x2={461.978}
      y2={106.185}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M467.79 193.69l-4.46-2.57a.235.235 0 00-.23 0l-5.88 3.38c-.03.02-.04.04-.04.08 0 .34-.27 61.76 0 61.91 0 .03.02.05.04.07l4.46 2.57c.07.04.17.04.24 0l5.87-3.38c.03-.02.04-.04.04-.07v-61.92c.01-.03-.01-.07-.04-.07z'
      fill='url(#banner_svg__SVGID_7_)'
    />
    <linearGradient
      id='banner_svg__SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1={462.755}
      y1={-89.02}
      x2={461.657}
      y2={11.116}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M464.9 198.49l-2.06-1.19a.108.108 0 00-.11 0l-2.7 1.56v57.42l2.06 1.19c.04.02.07.02.11 0l2.7-1.57v-57.41z'
      opacity={0.5}
      fill='url(#banner_svg__SVGID_8_)'
    />
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M365.51 164.66c-.25 0-.5-.07-.71-.19l-29.85-17.22a.689.689 0 01-.35-.56v-.04c.01-.24.13-.47.35-.59l22.66-13.09c.44-.25.98-.25 1.42 0l29.85 17.21c.15.09.26.23.32.4v.23a.73.73 0 01-.34.59l-22.64 13.07c-.22.13-.46.19-.71.19zm-7.2-31.44c-.17 0-.33.05-.49.13l-22.65 13.1c-.08.04-.13.12-.13.21s.04.18.13.23l29.85 17.21c.3.17.68.17.97 0l22.67-13.1c.06-.04.11-.1.12-.17a.25.25 0 00-.12-.22l-29.82-17.25c-.16-.09-.34-.13-.53-.14z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M151.36 51.56c-.16 0-.32-.04-.45-.12l-16.66-9.6a.49.49 0 01-.24-.4c0-.18.09-.35.24-.45l12.6-7.3c.28-.16.62-.16.9 0l16.69 9.6c.15.09.25.25.24.43 0 .17-.09.33-.24.41l-12.6 7.3c-.15.11-.31.14-.48.13zm-16.88-10.1l16.66 9.6c.14.08.31.08.44 0l12.66-7.34-16.68-9.64a.433.433 0 00-.44 0l-12.6 7.3-.11.26.07-.18z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={184.211}
      y1={-248.014}
      x2={150.929}
      y2={-249.112}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M178.34 38.17l-10.53-6.1a.678.678 0 00-.59 0l-9 5.2c-.06.03-.11.09-.11.16v7.74c.02.09.07.16.15.2l10.53 6.11a.7.7 0 00.6 0l9-5.2c.05-.03.1-.08.1-.14v-7.77a.303.303 0 00-.15-.2z'
      fill='url(#banner_svg__SVGID_9_)'
    />
    <linearGradient
      id='banner_svg__SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1={178.719}
      y1={-251.06}
      x2={158.1}
      y2={-251.744}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M178.49 38.37v7.76c0-.06-.05-.14-.15-.2l-10.53-6.1a.678.678 0 00-.59 0l-9 5.2c-.05.03-.1.08-.11.14v-7.74c0-.07.04-.14.11-.16l9-5.2c.19-.09.4-.09.59 0l10.53 6.1c.1.06.15.14.15.2z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_10_)'
    />
    <linearGradient
      id='banner_svg__SVGID_11_'
      gradientUnits='userSpaceOnUse'
      x1={178.496}
      y1={-252.084}
      x2={158.137}
      y2={-252.759}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M178.34 38.17l-10.53-6.1a.678.678 0 00-.59 0l-9 5.2c-.06.03-.11.09-.11.16.02.08.08.14.15.18l10.53 6.1a.7.7 0 00.6 0l9-5.2c.05-.03.1-.09.1-.15 0-.05-.05-.13-.15-.19z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_11_)'
    />
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={275.392}
      y1={23.536}
      x2={242.109}
      y2={22.429}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M269.52 309.72l-10.53-6.1c-.19-.1-.41-.1-.59 0l-9 5.19c-.06.03-.11.09-.11.16v7.74c.01.09.07.17.15.21l10.53 6.1c.19.09.41.09.59 0l9-5.2c.05-.02.1-.07.11-.14v-7.77a.31.31 0 00-.15-.19z'
      fill='url(#banner_svg__SVGID_12_)'
    />
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={269.9}
      y1={20.471}
      x2={249.281}
      y2={19.787}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M269.67 309.92v7.74a.258.258 0 00-.15-.2l-10.53-6.1c-.19-.1-.4-.1-.59 0l-9 5.19c-.05.03-.1.08-.11.14v-7.74c0-.07.04-.14.11-.16l9-5.19c.19-.1.4-.1.59 0l10.53 6.1c.08.04.14.13.15.22z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_13_)'
    />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={269.677}
      y1={19.466}
      x2={249.318}
      y2={18.791}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M269.52 309.72l-10.53-6.1c-.19-.1-.41-.1-.59 0l-9 5.19c-.06.03-.11.09-.11.16.02.08.08.15.15.19l10.53 6.1c.19.09.41.09.59 0l9-5.2c.06-.03.1-.08.11-.14a.303.303 0 00-.15-.2z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_14_)'
    />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={450.878}
      y1={-72.69}
      x2={417.596}
      y2={-73.797}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M445 213.52l-10.56-6.1a.678.678 0 00-.59 0l-9 5.2c-.06.03-.1.09-.1.16v7.68c.01.09.07.17.15.21l10.53 6.1c.19.09.41.09.59 0l9-5.2c.05-.02.1-.07.11-.13v-7.77a.217.217 0 00-.13-.15z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={445.385}
      y1={-75.714}
      x2={424.766}
      y2={-76.398}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M445.16 213.72v7.76a.258.258 0 00-.15-.2l-10.56-6.1c-.19-.1-.41-.1-.59 0l-9 5.19c-.05.03-.09.08-.1.14v-7.74c0-.07.04-.13.1-.16l9-5.2c.19-.09.41-.09.59 0l10.53 6.1c.09.03.16.11.18.21z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_16_)'
    />
    <linearGradient
      id='banner_svg__SVGID_17_'
      gradientUnits='userSpaceOnUse'
      x1={445.144}
      y1={-76.729}
      x2={424.786}
      y2={-77.404}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M445 213.52l-10.56-6.1a.678.678 0 00-.59 0l-9 5.2c-.06.03-.1.09-.1.16.02.08.08.15.15.19l10.53 6.1c.19.09.41.09.59 0l9-5.2c.06-.03.1-.08.11-.14 0-.08-.05-.16-.13-.21z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_17_)'
    />
    <linearGradient
      id='banner_svg__SVGID_18_'
      gradientUnits='userSpaceOnUse'
      x1={398.019}
      y1={-271.554}
      x2={364.737}
      y2={-272.652}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M392.15 14.64l-10.53-6.1c-.19-.1-.41-.1-.59 0l-9 5.19c-.06.04-.1.09-.11.16v7.74c.01.09.07.17.15.21l10.53 6.1c.19.1.41.1.6 0l9-5.19c.05-.03.09-.08.1-.14v-7.77c-.02-.09-.07-.17-.15-.2z'
      fill='url(#banner_svg__SVGID_18_)'
    />
    <linearGradient
      id='banner_svg__SVGID_19_'
      gradientUnits='userSpaceOnUse'
      x1={392.527}
      y1={-274.599}
      x2={371.908}
      y2={-275.283}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M392.3 14.83v7.76a.258.258 0 00-.15-.2l-10.53-6.1c-.19-.1-.41-.1-.59 0l-9 5.19c-.05.03-.1.08-.11.14v-7.74c.01-.07.04-.13.11-.16l9-5.19c.19-.1.41-.1.59 0l10.53 6.1c.08.04.13.12.15.2z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_19_)'
    />
    <linearGradient
      id='banner_svg__SVGID_20_'
      gradientUnits='userSpaceOnUse'
      x1={392.304}
      y1={-275.62}
      x2={371.946}
      y2={-276.295}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M392.15 14.64l-10.53-6.1c-.19-.1-.41-.1-.59 0l-9 5.19c-.06.04-.1.09-.11.16.02.08.08.15.15.19l10.53 6.1c.19.1.41.1.6 0l9-5.19c.05-.04.09-.09.1-.15-.02-.09-.07-.17-.15-.2z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_20_)'
    />
    <linearGradient
      id='banner_svg__SVGID_21_'
      gradientUnits='userSpaceOnUse'
      x1={127.169}
      y1={-252.073}
      x2={106.01}
      y2={-252.775}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M123.44 35.63l-6.7-3.88a.526.526 0 00-.39 0l-5.71 3.3c-.04.02-.06.06-.06.11v4.91c.01.06.05.11.1.14l6.7 3.84a.5.5 0 00.38 0l5.71-3.3c.05 0 .06-.05.06-.09v-4.91a.255.255 0 00-.09-.12z'
      fill='url(#banner_svg__SVGID_21_)'
    />
    <linearGradient
      id='banner_svg__SVGID_22_'
      gradientUnits='userSpaceOnUse'
      x1={123.672}
      y1={-254.001}
      x2={110.561}
      y2={-254.434}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M123.53 35.76v4.93a.238.238 0 00-.09-.13l-6.7-3.88a.526.526 0 00-.39 0l-5.71 3.3c-.03.03-.05.05-.06.09v-4.93c0-.04.03-.09.06-.11l5.71-3.3c.13-.05.26-.05.39 0l6.7 3.88c.05.04.08.1.09.15z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_22_)'
    />
    <linearGradient
      id='banner_svg__SVGID_23_'
      gradientUnits='userSpaceOnUse'
      x1={123.53}
      y1={-254.645}
      x2={110.582}
      y2={-255.069}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M123.44 35.63l-6.7-3.88a.526.526 0 00-.39 0l-5.71 3.3c-.04.02-.06.06-.06.11.02.05.05.09.1.12l6.7 3.88a.5.5 0 00.38 0l5.71-3.3c.04-.02.06-.05.06-.1a.238.238 0 00-.09-.13z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_23_)'
    />
    <linearGradient
      id='banner_svg__SVGID_24_'
      gradientUnits='userSpaceOnUse'
      x1={113.82}
      y1={1.114}
      x2={268.597}
      y2={1.114}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M114.32 293.04h16.73c1.26 0 2.5.33 3.6.96l36.79 21.08c3.46 1.97 7.7 1.97 11.15 0l85.5-48.78'
      fill='none'
      stroke='url(#banner_svg__SVGID_24_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M192.53 359.96c-.25 0-.5-.06-.71-.19l-29.85-17.21a.706.706 0 01-.35-.57v-.04c.01-.24.13-.47.35-.59l22.66-13.08c.44-.25.98-.25 1.42 0l29.85 17.21c.14.09.26.23.32.39v.23c0 .24-.14.47-.34.59l-22.64 13.07c-.22.13-.47.19-.71.19zm-7.2-31.42a.86.86 0 00-.49.13l-22.65 13.09c-.07.04-.13.12-.13.2 0 .09.04.18.13.23l29.85 17.21c.3.17.68.17.97 0l22.66-13.09c.06-.04.11-.11.12-.18a.28.28 0 00-.12-.23l-29.85-17.21a.94.94 0 00-.49-.15z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M33.04 230.96c-.25 0-.5-.06-.71-.19L2.5 213.56a.689.689 0 01-.35-.56v-.06c0-.24.14-.47.35-.59l22.66-13.09c.44-.25.98-.25 1.42 0l29.85 17.21c.15.09.26.23.32.4v.23a.73.73 0 01-.34.59l-22.64 13.08c-.22.13-.48.19-.73.19zm-7.2-31.43c-.17 0-.33.05-.49.13L2.73 212.74c-.08.04-.13.12-.13.21s.05.18.13.23l29.85 17.19c.3.17.68.17.97 0l22.66-13.09c.06-.04.11-.1.12-.17 0-.09-.05-.17-.12-.22l-29.85-17.24c-.16-.08-.34-.12-.52-.12z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M120.05 276.15c-.24 0-.49-.06-.7-.19l-29.86-17.22a.702.702 0 01-.34-.56v-.06c.01-.24.14-.46.34-.58l22.67-13.09c.44-.25.97-.25 1.41 0l29.85 17.21c.15.09.26.23.32.4v.23c-.01.24-.13.47-.35.59l-22.66 13.09c-.21.12-.44.18-.68.18zm-7.2-31.43c-.17 0-.34.04-.49.13l-22.63 13.08c-.12.07-.16.23-.09.34.02.04.05.06.09.09l29.84 17.21c.3.17.68.17.97 0l22.66-13.09c.07-.04.12-.1.13-.17a.24.24 0 00-.13-.22l-29.87-17.24a.88.88 0 00-.48-.09v-.04z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M271.22 348.75c-.16 0-.32-.04-.45-.12l-16.66-9.6a.49.49 0 01-.24-.4c-.01-.18.08-.36.24-.45l12.65-7.31c.28-.16.62-.16.9 0l16.66 9.6a.497.497 0 010 .85l-12.65 7.31c-.14.08-.3.13-.45.12zm-16.88-10.12l16.66 9.63c.14.08.31.08.44 0l12.71-7.35-16.66-9.6a.433.433 0 00-.44 0l-12.65 7.3-.11.26.05-.24z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M90.48 208.39c-.16 0-.32-.04-.45-.12l-16.66-9.6a.487.487 0 01-.24-.39c-.01-.19.08-.36.24-.46l12.6-7.3c.28-.16.62-.16.9 0l16.66 9.6c.15.09.25.25.24.43 0 .17-.09.33-.24.41l-12.6 7.3c-.15.1-.3.14-.45.13zm-16.89-10.11l16.66 9.58c.14.08.31.08.44 0l12.66-7.34-16.68-9.61a.433.433 0 00-.44 0l-12.6 7.3-.11.27.07-.2z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_25_'
      gradientUnits='userSpaceOnUse'
      x1={208.089}
      y1={42.754}
      x2={186.93}
      y2={42.052}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M204.36 330.44l-6.7-3.88a.526.526 0 00-.39 0l-5.7 3.3c-.04.02-.07.06-.07.11v4.92c0 .05 0 .09.1.13l6.7 3.88a.5.5 0 00.38 0l5.71-3.3c.04 0 .06-.05.06-.09v-4.94a.238.238 0 00-.09-.13z'
      fill='url(#banner_svg__SVGID_25_)'
    />
    <linearGradient
      id='banner_svg__SVGID_26_'
      gradientUnits='userSpaceOnUse'
      x1={204.593}
      y1={40.795}
      x2={191.481}
      y2={40.362}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M204.45 330.57v4.93a.196.196 0 00-.09-.13l-6.7-3.88a.526.526 0 00-.39 0l-5.7 3.27c-.04.02-.06.04-.07.09v-4.92c0-.04.03-.09.07-.11l5.7-3.3c.13-.05.26-.05.39 0l6.7 3.88c.06.03.1.11.09.17z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_26_)'
    />
    <linearGradient
      id='banner_svg__SVGID_27_'
      gradientUnits='userSpaceOnUse'
      x1={204.451}
      y1={40.164}
      x2={191.503}
      y2={39.741}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M204.36 330.44l-6.7-3.88a.526.526 0 00-.39 0l-5.7 3.3c-.04.02-.07.06-.07.11.02.05.05.09.1.12l6.7 3.88a.5.5 0 00.38 0l5.71-3.3c.04-.02.06-.05.06-.1a.238.238 0 00-.09-.13z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_27_)'
    />
    <linearGradient
      id='banner_svg__SVGID_28_'
      gradientUnits='userSpaceOnUse'
      x1={17.214}
      y1={-45.641}
      x2={-3.946}
      y2={-46.343}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M13.48 242.06l-6.7-3.88a.5.5 0 00-.38 0l-5.71 3.28c-.04.02-.06.06-.06.11v4.91c0 .06.05.12.1.13l6.7 3.88a.5.5 0 00.38 0l5.71-3.3s.06-.05.06-.09v-4.94a.195.195 0 00-.1-.1z'
      fill='url(#banner_svg__SVGID_28_)'
    />
    <linearGradient
      id='banner_svg__SVGID_29_'
      gradientUnits='userSpaceOnUse'
      x1={13.726}
      y1={-47.583}
      x2={0.615}
      y2={-48.014}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M13.58 242.18v4.93c0-.04 0-.09-.1-.13l-6.7-3.88a.5.5 0 00-.38 0l-5.71 3.29c-.04.02-.05.05-.06.09v-4.91c0-.04.03-.09.06-.11l5.71-3.3a.5.5 0 01.38 0l6.7 3.91c.07-.01.1.06.1.11z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_29_)'
    />
    <linearGradient
      id='banner_svg__SVGID_30_'
      gradientUnits='userSpaceOnUse'
      x1={13.584}
      y1={-48.231}
      x2={0.636}
      y2={-48.654}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M13.48 242.06l-6.7-3.88a.5.5 0 00-.38 0l-5.71 3.28c-.04.02-.06.06-.06.11 0 0 0 .08.1.12l6.7 3.88a.5.5 0 00.38 0l5.71-3.3c.04-.02.05-.05.06-.1 0-.04-.03-.11-.1-.11z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_30_)'
    />
    <linearGradient
      id='banner_svg__SVGID_31_'
      gradientUnits='userSpaceOnUse'
      x1={62.006}
      y1={-192.171}
      x2={223.272}
      y2={-192.171}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M62.51 135.67l84.16-48.85c2.39-1.4 5.11-2.12 7.88-2.12h21.51c2.7 0 5.35-.69 7.7-2.03l39.02-22.06'
      fill='none'
      stroke='url(#banner_svg__SVGID_31_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_32_'
      gradientUnits='userSpaceOnUse'
      x1={456.915}
      y1={32.045}
      x2={435.756}
      y2={31.352}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path
      d='M453.19 319.7l-6.7-3.83a.424.424 0 00-.38 0l-5.71 3.3c-.04.02-.06.05-.06.1v4.92c0 .04 0 .09.1.13l6.7 3.89a.5.5 0 00.38 0l5.71-3.31c.04-.02.06-.05.06-.09v-4.98a.17.17 0 00-.1-.13z'
      fill='url(#banner_svg__SVGID_32_)'
    />
    <linearGradient
      id='banner_svg__SVGID_33_'
      gradientUnits='userSpaceOnUse'
      x1={453.432}
      y1={30.07}
      x2={440.318}
      y2={29.629}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M453.28 319.82v4.93c0-.04 0-.09-.1-.13l-6.7-3.88a.424.424 0 00-.38 0l-5.71 3.3s-.06.05-.06.09v-4.92c0-.05.03-.08.06-.1l5.71-3.3c.12-.06.26-.06.38 0l6.7 3.88c.07.04.1.09.1.13z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_33_)'
    />
    <linearGradient
      id='banner_svg__SVGID_34_'
      gradientUnits='userSpaceOnUse'
      x1={453.288}
      y1={29.464}
      x2={440.339}
      y2={29.032}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#e7f0ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M453.19 319.7l-6.7-3.83a.424.424 0 00-.38 0l-5.71 3.3c-.04.02-.06.05-.06.1.01.05.04.1.1.12l6.7 3.89a.5.5 0 00.38 0l5.71-3.31c.04-.01.06-.04.06-.09-.01-.01-.04-.15-.1-.18z'
      opacity={0.2}
      fill='url(#banner_svg__SVGID_34_)'
    />
    <linearGradient
      id='banner_svg__SVGID_35_'
      gradientUnits='userSpaceOnUse'
      x1={67.685}
      y1={-167.528}
      x2={215.01}
      y2={-167.528}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M68.19 143.33l21.13-11.97a8.189 8.189 0 013.99-1.04h22.27c1.43 0 2.84-.38 4.07-1.09l44.9-25.89a8.161 8.161 0 014.07-1.09h45.9'
      fill='none'
      stroke='url(#banner_svg__SVGID_35_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_36_'
      gradientUnits='userSpaceOnUse'
      x1={0.678}
      y1={-120.48}
      x2={155.922}
      y2={-202.049}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M84.91 132.08v11.7c0 11.9-14.92 21.55-33.3 21.55s-33.3-9.65-33.3-21.55c-.18 0-.24-1.38-.24-3.16 0-.95 0-2.03.05-3.11.07-2.78.2-5.4.2-5.4h5.4c5.95-5.9 16.2-9.81 27.95-9.81s22.01 3.91 27.95 9.81l5.29-.03z'
      fill='url(#banner_svg__SVGID_36_)'
    />
    <path
      className='banner_svg__st40'
      d='M84.91 138.42v2.84c-4.12 9.45-17.51 16.4-33.3 16.4-16.13 0-29.7-7.2-33.56-16.97 0-.95 0-2.03.05-3.11 1.93 10.8 16.2 19.17 33.52 19.17 16.78-.04 30.77-7.98 33.29-18.33z'
      opacity={0.8}
    />
    <linearGradient
      id='banner_svg__SVGID_37_'
      gradientUnits='userSpaceOnUse'
      x1={-102.156}
      y1={-158.236}
      x2={120.4}
      y2={-158.236}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <ellipse cx={51.58} cy={132.08} rx={33.33} ry={21.55} fill='url(#banner_svg__SVGID_37_)' />
    <ellipse cx={51.58} cy={132.08} rx={33.33} ry={21.55} opacity={0.2} fill='#fff' />
    <linearGradient
      id='banner_svg__SVGID_38_'
      gradientUnits='userSpaceOnUse'
      x1={-96.243}
      y1={-158.938}
      x2={117.754}
      y2={-158.938}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <ellipse cx={51.58} cy={131.38} rx={32.04} ry={20.72} fill='url(#banner_svg__SVGID_38_)' />
    <linearGradient
      id='banner_svg__SVGID_39_'
      gradientUnits='userSpaceOnUse'
      x1={51.607}
      y1={-176.592}
      x2={51.607}
      y2={-120.764}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M75.91 131.36v.81c-.67 8.3-11.29 14.89-24.3 14.89s-23.62-6.59-24.3-14.89v-.81c0-8.68 10.89-15.72 24.3-15.72s24.3 7.06 24.3 15.72z'
      fill='url(#banner_svg__SVGID_39_)'
    />
    <linearGradient
      id='banner_svg__SVGID_40_'
      gradientUnits='userSpaceOnUse'
      x1={-168.856}
      y1={-158.127}
      x2={89.278}
      y2={-158.127}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M75.87 132.19c-.67 8.3-11.29 14.89-24.3 14.89s-23.62-6.59-24.3-14.89c.67-8.3 11.29-14.89 24.3-14.89s23.64 6.59 24.3 14.89z'
      fill='url(#banner_svg__SVGID_40_)'
    />
    <linearGradient
      id='banner_svg__SVGID_41_'
      gradientUnits='userSpaceOnUse'
      x1={57.227}
      y1={-124.188}
      x2={145.277}
      y2={-124.188}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M57.73 159.56l19.51 11.05c2.39 1.37 5.09 2.09 7.83 2.09h59.71'
      fill='none'
      stroke='url(#banner_svg__SVGID_41_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_42_'
      gradientUnits='userSpaceOnUse'
      x1={74.327}
      y1={-54.216}
      x2={129.004}
      y2={-54.216}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M81.54 271.76l3.86-2.42c.86-.49 1.16-1.59.67-2.46-.17-.3-.41-.53-.71-.69l-8.15-4.82a4.801 4.801 0 01-2.38-4.17v-23.24c0-1.76.95-3.38 2.47-4.25l51.21-29.28'
      fill='none'
      stroke='url(#banner_svg__SVGID_42_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_43_'
      gradientUnits='userSpaceOnUse'
      x1={75.524}
      y1={-86.414}
      x2={424.675}
      y2={-86.414}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M76.02 153.21l13.26 7.71a9.795 9.795 0 009.77 0l47.88-27.47a8.106 8.106 0 014.05-1.08h41.67c1.4 0 2.79.36 4.01 1.05l85.71 48.6a8.101 8.101 0 008.16-.08l31.85-18.86a8.028 8.028 0 014.14-1.13h22.65c1.44 0 2.85.38 4.1 1.1l52.2 30.38a8.153 8.153 0 014.04 7.04v28.4a8.116 8.116 0 004.13 7.08l8.67 4.9c1.76 1.01 2.39 3.25 1.38 5.02-.32.56-.77 1.02-1.31 1.34l-47.51 28.21'
      fill='none'
      stroke='url(#banner_svg__SVGID_43_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_44_'
      gradientUnits='userSpaceOnUse'
      x1={290.178}
      y1={-258.276}
      x2={395.076}
      y2={-258.276}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#00bfa5' />
      <stop offset={0.5} stopColor='#6dd9ca' />
      <stop offset={1} stopColor='#b5f2ea' />
    </linearGradient>
    <path
      d='M394.58 50.26l-33.09-19.22a10.91 10.91 0 00-5.48-1.48h-15.44c-1.91 0-3.79-.5-5.45-1.46l-22.64-13.07a9.112 9.112 0 00-9-.05l-12.8 7.2'
      fill='none'
      stroke='url(#banner_svg__SVGID_44_)'
      strokeLinecap='round'
      strokeMiterlimit={10}
    />
    <g>
      <linearGradient
        id='banner_svg__SVGID_45_'
        gradientUnits='userSpaceOnUse'
        x1={251.191}
        y1={-174.613}
        x2={556.054}
        y2={-189.607}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M479.32 154.82c-.03.42-.25.81-.62 1.03l-60.45 34.91c-.98.58-2.21.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13V60.41v-.1c.03-.41.26-.78.62-.99l60.45-34.9a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.15.06z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_45_)'
      />
      <linearGradient
        id='banner_svg__SVGID_46_'
        gradientUnits='userSpaceOnUse'
        x1={256.735}
        y1={-119.948}
        x2={552.858}
        y2={-134.51}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M479.31 137.55v17.28c-.02.42-.25.81-.62 1.03l-60.45 34.9c-.98.57-2.21.57-3.19 0l-61.66-35.45c-.41-.23-.64-.68-.62-1.14v-17.21c-.01.45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.94c.38-.22.61-.61.63-1.04v.03z'
        fill='url(#banner_svg__SVGID_46_)'
      />
      <linearGradient
        id='banner_svg__SVGID_47_'
        gradientUnits='userSpaceOnUse'
        x1={496.057}
        y1={-160.854}
        x2={-131.939}
        y2={-99.941}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M478.68 136.41l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.58.58-.62.99v.1c-.01.45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.45-34.93c.6-.34.81-1.12.47-1.72-.11-.2-.27-.36-.47-.47v.04z'
        fill='url(#banner_svg__SVGID_47_)'
      />
      <linearGradient
        id='banner_svg__SVGID_48_'
        gradientUnits='userSpaceOnUse'
        x1={476.99}
        y1={-143.679}
        x2={425.527}
        y2={-111.027}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M426.35 178.06c-.16 0-.31-.09-.39-.23-.13-.22-.05-.5.16-.62l40.01-23.07c.22-.13.49-.05.62.16.12.22.04.5-.17.62L426.57 178c-.07.04-.15.06-.22.06z'
        fill='url(#banner_svg__SVGID_48_)'
      />
      <linearGradient
        id='banner_svg__SVGID_49_'
        gradientUnits='userSpaceOnUse'
        x1={477.619}
        y1={-142.694}
        x2={426.156}
        y2={-110.041}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M469.8 153c-.16 0-.31-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.13.49-.07.62.14.14.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.08.04-.15.05-.22.05z'
        fill='url(#banner_svg__SVGID_49_)'
      />
      <linearGradient
        id='banner_svg__SVGID_50_'
        gradientUnits='userSpaceOnUse'
        x1={477.714}
        y1={-142.548}
        x2={426.251}
        y2={-109.895}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M471.79 151.96a.449.449 0 01-.47-.43c-.01-.18.09-.35.25-.42l.42-.22c.22-.12.49-.04.61.18s.04.5-.18.61h-.01l-.42.23c-.06.03-.13.06-.2.05z'
        fill='url(#banner_svg__SVGID_50_)'
      />
      <linearGradient
        id='banner_svg__SVGID_51_'
        gradientUnits='userSpaceOnUse'
        x1={477.776}
        y1={-142.441}
        x2={426.313}
        y2={-109.797}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M473.58 150.97a.449.449 0 01-.47-.43c-.01-.18.09-.35.25-.42l.43-.23c.22-.11.49-.03.6.19.12.22.04.5-.18.61h-.01l-.42.22c-.06.04-.13.07-.2.06z'
        fill='url(#banner_svg__SVGID_51_)'
      />
      <linearGradient
        id='banner_svg__SVGID_52_'
        gradientUnits='userSpaceOnUse'
        x1={525.633}
        y1={-148.058}
        x2={410.557}
        y2={-145.475}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M473.67 121.21v15.74c-.02.38-.23.71-.56.9l-55.07 31.8c-.9.52-2 .52-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.22.79.58.99l56.16 32.29c.9.51 2 .51 2.9 0l55.1-31.8c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_52_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_53_'
          gradientUnits='userSpaceOnUse'
          x1={256.735}
          y1={-145.626}
          x2={552.858}
          y2={-160.197}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.31 111.87v17.28c-.02.42-.25.81-.62 1.02l-60.45 34.91c-.99.57-2.2.57-3.19 0l-61.66-35.45c-.41-.23-.65-.68-.62-1.14v-17.2c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.21.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04h-.01z'
          fill='url(#banner_svg__SVGID_53_)'
        />
        <linearGradient
          id='banner_svg__SVGID_54_'
          gradientUnits='userSpaceOnUse'
          x1={496.058}
          y1={-186.529}
          x2={-131.937}
          y2={-125.607}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M478.68 110.73l-61.65-35.45c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.59.59-.62 1v.1c0 .45.23.86.62 1.09l61.66 35.44c.98.58 2.21.58 3.19 0l60.45-34.9c.6-.34.81-1.11.48-1.7-.12-.2-.28-.36-.48-.48z'
          fill='url(#banner_svg__SVGID_54_)'
        />
        <linearGradient
          id='banner_svg__SVGID_55_'
          gradientUnits='userSpaceOnUse'
          x1={476.991}
          y1={-169.354}
          x2={425.528}
          y2={-136.711}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M426.35 152.37c-.25 0-.45-.21-.44-.46 0-.15.08-.31.22-.38l40.01-23.07c.2-.14.49-.1.63.1s.1.49-.1.63c-.03.02-.05.04-.08.04l-40.01 23.07a.31.31 0 01-.23.07z'
          fill='url(#banner_svg__SVGID_55_)'
        />
        <linearGradient
          id='banner_svg__SVGID_56_'
          gradientUnits='userSpaceOnUse'
          x1={477.591}
          y1={-168.417}
          x2={426.128}
          y2={-135.764}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M469.8 127.33a.46.46 0 11-.2-.9l.42-.23c.23-.11.49-.03.61.19.12.23.03.5-.19.61l-.42.23a.54.54 0 01-.22.1z'
          fill='url(#banner_svg__SVGID_56_)'
        />
        <linearGradient
          id='banner_svg__SVGID_57_'
          gradientUnits='userSpaceOnUse'
          x1={477.714}
          y1={-168.225}
          x2={426.251}
          y2={-135.582}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M471.79 126.27c-.25 0-.45-.2-.45-.45 0-.16.09-.32.23-.4l.42-.23a.44.44 0 01.61.19c.12.23.04.5-.19.61l-.42.22c-.06.05-.13.07-.2.06z'
          fill='url(#banner_svg__SVGID_57_)'
        />
        <linearGradient
          id='banner_svg__SVGID_58_'
          gradientUnits='userSpaceOnUse'
          x1={477.776}
          y1={-168.127}
          x2={426.313}
          y2={-135.475}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M473.58 125.28a.432.432 0 01-.43-.23.456.456 0 01.19-.61l.43-.22c.23-.12.49-.03.6.19.12.23.03.5-.19.61l-.42.22c-.06.04-.12.04-.18.04z'
          fill='url(#banner_svg__SVGID_58_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_59_'
        gradientUnits='userSpaceOnUse'
        x1={525.633}
        y1={-173.759}
        x2={410.556}
        y2={-171.167}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M473.67 95.53v15.74c-.02.38-.23.71-.56.9l-55.08 31.79c-.9.51-2 .51-2.9 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04V94.99c0 .41.22.79.58 1l56.16 32.28c.9.52 2 .52 2.9 0l55.11-31.79c.34-.2.56-.56.57-.95h-.05z'
        fill='url(#banner_svg__SVGID_59_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_60_'
          gradientUnits='userSpaceOnUse'
          x1={256.735}
          y1={-171.015}
          x2={552.858}
          y2={-185.577}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.31 86.48v17.28c-.02.42-.25.82-.62 1.03l-60.45 34.89c-.98.57-2.21.57-3.19 0l-61.66-35.45c-.41-.23-.65-.68-.62-1.14V85.9c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.21.58 3.19 0l60.44-34.9c.37-.22.62-.6.63-1.05z'
          fill='url(#banner_svg__SVGID_60_)'
        />
        <linearGradient
          id='banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={496.058}
          y1={-211.91}
          x2={-131.937}
          y2={-150.988}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M478.68 85.35L417.03 49.9c-.99-.57-2.21-.57-3.2 0l-60.45 34.9c-.36.21-.58.58-.62.99v.11c0 .45.23.86.62 1.08l61.66 35.45c.98.58 2.21.58 3.19 0l60.45-34.9c.38-.22.61-.61.63-1.04.02-.46-.22-.91-.63-1.14z'
          fill='url(#banner_svg__SVGID_61_)'
        />
        <linearGradient
          id='banner_svg__SVGID_62_'
          gradientUnits='userSpaceOnUse'
          x1={476.991}
          y1={-194.744}
          x2={425.528}
          y2={-162.091}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M426.35 126.99c-.25 0-.45-.21-.44-.46 0-.15.08-.31.22-.38l40.01-23.08c.22-.12.49-.05.62.17.12.22.04.49-.17.61l-40.01 23.07a.31.31 0 01-.23.07z'
          fill='url(#banner_svg__SVGID_62_)'
        />
        <linearGradient
          id='banner_svg__SVGID_63_'
          gradientUnits='userSpaceOnUse'
          x1={477.616}
          y1={-193.756}
          x2={426.154}
          y2={-161.112}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M469.8 101.94c-.16 0-.31-.09-.4-.23-.12-.23-.03-.5.19-.61l.42-.23c.23-.12.49-.04.61.19s.04.5-.19.61l-.42.23c-.07.03-.14.05-.21.04z'
          fill='url(#banner_svg__SVGID_63_)'
        />
        <linearGradient
          id='banner_svg__SVGID_64_'
          gradientUnits='userSpaceOnUse'
          x1={477.715}
          y1={-193.613}
          x2={426.252}
          y2={-160.96}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M471.79 100.89c-.17 0-.32-.09-.41-.24a.445.445 0 01.19-.6l.42-.23c.22-.12.49-.04.61.18v.01c.12.22.04.49-.18.59 0 0-.01 0-.01.01l-.42.23c-.06.04-.13.06-.2.05z'
          fill='url(#banner_svg__SVGID_64_)'
        />
        <linearGradient
          id='banner_svg__SVGID_65_'
          gradientUnits='userSpaceOnUse'
          x1={477.779}
          y1={-193.504}
          x2={426.316}
          y2={-160.851}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M473.58 99.9c-.17 0-.32-.09-.41-.24a.445.445 0 01.19-.6l.43-.23c.23-.09.5.03.59.26.07.2.01.41-.16.53l-.42.23c-.08.04-.15.06-.22.05z'
          fill='url(#banner_svg__SVGID_65_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_66_'
        gradientUnits='userSpaceOnUse'
        x1={525.633}
        y1={-199.13}
        x2={410.556}
        y2={-196.547}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M473.67 70.16v15.72c-.02.38-.23.71-.56.9l-55.07 31.79c-.9.52-2 .52-2.9 0l-56.17-32.21c-.37-.22-.59-.61-.57-1.04V69.61c-.01.41.22.79.58.99l56.16 32.29c.9.52 2 .52 2.9 0l55.11-31.83c.34-.19.56-.55.57-.95l-.05.05z'
        fill='url(#banner_svg__SVGID_66_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_67_'
          gradientUnits='userSpaceOnUse'
          x1={256.735}
          y1={-196.499}
          x2={552.858}
          y2={-211.061}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.31 61v17.26c-.02.42-.25.81-.62 1.03l-60.45 34.92c-.98.57-2.21.57-3.19 0L353.4 78.74c-.41-.23-.64-.67-.62-1.13v-17.2c-.01.45.23.86.62 1.09l61.66 35.45c.99.57 2.2.57 3.19 0l60.44-34.89c.37-.22.61-.62.62-1.06z'
          fill='url(#banner_svg__SVGID_67_)'
        />
        <linearGradient
          id='banner_svg__SVGID_68_'
          gradientUnits='userSpaceOnUse'
          x1={221.544}
          y1={-221.864}
          x2={496.337}
          y2={-232.835}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M479.31 60.94V61a1.194 1.194 0 01-.62 1.05l-20.22 11.72-22.03 12.68-18.19 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.04-12.66-20.23-11.64c-.4-.22-.64-.64-.63-1.09v-.1c.03-.41.26-.78.62-.99l60.45-34.9a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47.11.19.16.4.17.6z'
          fill='url(#banner_svg__SVGID_68_)'
        />
        <linearGradient
          id='banner_svg__SVGID_69_'
          gradientUnits='userSpaceOnUse'
          x1={476.99}
          y1={-220.226}
          x2={425.527}
          y2={-187.573}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M426.35 101.51c-.16 0-.31-.09-.39-.23-.13-.22-.05-.5.16-.62l40.01-23.07c.22-.13.49-.05.62.16.12.22.04.5-.17.62l-40.01 23.07a.32.32 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_69_)'
        />
        <linearGradient
          id='banner_svg__SVGID_70_'
          gradientUnits='userSpaceOnUse'
          x1={477.619}
          y1={-219.24}
          x2={426.156}
          y2={-186.588}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M469.8 76.46c-.16 0-.31-.1-.4-.24a.445.445 0 01.19-.6l.42-.23c.21-.14.49-.07.62.14.14.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23a.63.63 0 01-.22.05z'
          fill='url(#banner_svg__SVGID_70_)'
        />
        <linearGradient
          id='banner_svg__SVGID_71_'
          gradientUnits='userSpaceOnUse'
          x1={477.688}
          y1={-219.136}
          x2={426.225}
          y2={-186.483}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M471.79 75.41c-.25.06-.5-.09-.56-.34-.06-.25.09-.5.34-.56l.42-.23c.23-.11.49-.03.61.19s.04.5-.18.61h-.01l-.41.24c-.07.05-.14.09-.21.09z'
          fill='url(#banner_svg__SVGID_71_)'
        />
        <linearGradient
          id='banner_svg__SVGID_72_'
          gradientUnits='userSpaceOnUse'
          x1={477.776}
          y1={-218.987}
          x2={426.313}
          y2={-186.344}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M473.58 74.42a.449.449 0 01-.47-.43c-.01-.18.09-.35.25-.42l.43-.22c.22-.11.49-.03.6.19s.04.5-.18.61h-.01l-.42.23c-.06.03-.13.05-.2.04z'
          fill='url(#banner_svg__SVGID_72_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_73_'
        gradientUnits='userSpaceOnUse'
        x1={104.821}
        y1={-198.649}
        x2={409.684}
        y2={-213.643}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M332.94 130.79c-.03.42-.25.81-.62 1.03l-60.46 34.9c-.98.58-2.21.58-3.19 0l-61.66-35.45c-.41-.22-.65-.67-.62-1.13V36.38v-.1c.03-.41.26-.78.62-.99L267.46.39a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47V130.79h.17z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_73_)'
      />
      <linearGradient
        id='banner_svg__SVGID_74_'
        gradientUnits='userSpaceOnUse'
        x1={110.365}
        y1={-143.979}
        x2={406.48}
        y2={-158.541}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M332.94 113.52v17.28c-.02.42-.25.82-.62 1.03l-60.46 34.9c-.98.57-2.21.57-3.19 0l-61.65-35.45c-.4-.23-.65-.68-.62-1.14v-17.21c-.01.45.23.87.63 1.09l61.65 35.45c.99.57 2.2.57 3.19 0l60.46-34.9c.38-.22.61-.62.61-1.05z'
        fill='url(#banner_svg__SVGID_74_)'
      />
      <linearGradient
        id='banner_svg__SVGID_75_'
        gradientUnits='userSpaceOnUse'
        x1={349.688}
        y1={-184.88}
        x2={-278.317}
        y2={-123.967}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M332.31 112.38l-61.67-35.45a3.19 3.19 0 00-3.19 0L207 111.84c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.65 35.45c.99.57 2.2.57 3.19 0l60.46-34.9c.38-.22.61-.61.62-1.04.03-.48-.21-.92-.62-1.15z'
        fill='url(#banner_svg__SVGID_75_)'
      />
      <linearGradient
        id='banner_svg__SVGID_76_'
        gradientUnits='userSpaceOnUse'
        x1={330.625}
        y1={-167.702}
        x2={279.162}
        y2={-135.049}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M280 154.03a.47.47 0 01-.4-.23c-.12-.22-.04-.5.17-.62l40.01-23.07c.21-.13.48-.05.6.15l.01.01c.13.22.05.5-.16.62l-40.01 23.07c-.07.05-.15.07-.22.07z'
        fill='url(#banner_svg__SVGID_76_)'
      />
      <linearGradient
        id='banner_svg__SVGID_77_'
        gradientUnits='userSpaceOnUse'
        x1={331.248}
        y1={-166.726}
        x2={279.785}
        y2={-134.073}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M323.43 128.97c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.22-.12.49-.04.61.18v.01c.11.22.03.49-.19.6l-.43.22c-.06.05-.13.07-.2.06z'
        fill='url(#banner_svg__SVGID_77_)'
      />
      <linearGradient
        id='banner_svg__SVGID_78_'
        gradientUnits='userSpaceOnUse'
        x1={331.338}
        y1={-166.574}
        x2={279.875}
        y2={-133.921}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M325.41 127.93a.45.45 0 01-.21-.85l.42-.22c.21-.14.49-.07.62.14.14.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23a.63.63 0 01-.22.05z'
        fill='url(#banner_svg__SVGID_78_)'
      />
      <linearGradient
        id='banner_svg__SVGID_79_'
        gradientUnits='userSpaceOnUse'
        x1={331.38}
        y1={-166.513}
        x2={279.917}
        y2={-133.869}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M327.2 126.94c-.25.05-.49-.1-.55-.34-.05-.25.1-.5.34-.56l.42-.23c.23-.11.49-.03.61.19.12.23.03.5-.19.61l-.42.22c-.06.07-.14.1-.21.11z'
        fill='url(#banner_svg__SVGID_79_)'
      />
      <linearGradient
        id='banner_svg__SVGID_80_'
        gradientUnits='userSpaceOnUse'
        x1={379.254}
        y1={-172.097}
        x2={264.187}
        y2={-169.514}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M327.35 97.16v15.74c-.03.38-.24.71-.57.9l-55.06 31.8c-.9.52-2.01.52-2.91 0l-56.22-32.24c-.37-.21-.59-.61-.57-1.04V96.65c0 .4.22.78.57.99l56.16 32.29c.9.51 2.01.51 2.91 0l55.06-31.8c.37-.18.61-.56.63-.97z'
        fill='url(#banner_svg__SVGID_80_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_81_'
          gradientUnits='userSpaceOnUse'
          x1={110.365}
          y1={-169.652}
          x2={406.48}
          y2={-184.224}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M332.94 87.83v17.28c-.02.42-.25.81-.62 1.02l-60.46 34.91c-.99.57-2.2.57-3.19 0l-61.65-35.45c-.4-.23-.65-.68-.62-1.14V87.26c0 .45.23.86.63 1.09l61.65 35.44c.98.58 2.21.58 3.19 0l60.45-34.91c.37-.22.61-.61.62-1.05z'
          fill='url(#banner_svg__SVGID_81_)'
        />
        <linearGradient
          id='banner_svg__SVGID_82_'
          gradientUnits='userSpaceOnUse'
          x1={349.689}
          y1={-210.556}
          x2={-278.316}
          y2={-149.633}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M332.31 86.7l-61.67-35.44a3.19 3.19 0 00-3.19 0L207 86.16c-.36.2-.59.58-.62.99a.17.17 0 000 .1c0 .45.23.86.63 1.09l61.65 35.44c.98.58 2.21.58 3.19 0l60.45-34.9c.6-.36.8-1.14.44-1.74-.1-.18-.25-.33-.43-.44z'
          fill='url(#banner_svg__SVGID_82_)'
        />
        <linearGradient
          id='banner_svg__SVGID_83_'
          gradientUnits='userSpaceOnUse'
          x1={330.627}
          y1={-193.376}
          x2={279.164}
          y2={-160.733}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M280 128.34a.47.47 0 01-.4-.23.452.452 0 01.17-.61l40.01-23.07c.2-.14.49-.1.63.1s.1.49-.1.63c-.03.02-.05.04-.08.05l-40.01 23.07a.31.31 0 01-.22.06z'
          fill='url(#banner_svg__SVGID_83_)'
        />
        <linearGradient
          id='banner_svg__SVGID_84_'
          gradientUnits='userSpaceOnUse'
          x1={331.246}
          y1={-192.408}
          x2={279.783}
          y2={-159.755}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M323.43 103.3a.45.45 0 01-.21-.85l.42-.22a.44.44 0 01.61.19c.12.22.04.5-.19.61l-.43.23c-.06.02-.13.05-.2.04z'
          fill='url(#banner_svg__SVGID_84_)'
        />
        <linearGradient
          id='banner_svg__SVGID_85_'
          gradientUnits='userSpaceOnUse'
          x1={331.336}
          y1={-192.254}
          x2={279.873}
          y2={-159.61}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M325.41 102.24a.47.47 0 01-.4-.23c-.12-.22-.04-.5.18-.61h.01l.42-.22c.22-.12.49-.04.61.18v.01c.12.23.03.5-.19.61l-.42.22c-.07.03-.14.04-.21.04z'
          fill='url(#banner_svg__SVGID_85_)'
        />
        <linearGradient
          id='banner_svg__SVGID_86_'
          gradientUnits='userSpaceOnUse'
          x1={331.409}
          y1={-192.154}
          x2={279.946}
          y2={-159.501}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M327.2 101.25a.47.47 0 01-.4-.23c-.12-.22-.04-.5.18-.61h.01l.42-.23c.23-.12.49-.04.61.19s.04.5-.19.61l-.42.22c-.06.04-.14.05-.21.05z'
          fill='url(#banner_svg__SVGID_86_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_87_'
        gradientUnits='userSpaceOnUse'
        x1={379.254}
        y1={-197.775}
        x2={264.187}
        y2={-195.184}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M327.35 71.5v15.76c-.03.38-.24.71-.57.9l-55.06 31.8c-.9.51-2.01.51-2.91 0l-56.22-32.28c-.37-.22-.59-.61-.57-1.04V70.96c0 .41.22.79.57 1l56.16 32.28c.9.52 2.01.52 2.91 0l55.06-31.79c.34-.2.57-.56.58-.95h.05z'
        fill='url(#banner_svg__SVGID_87_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_88_'
          gradientUnits='userSpaceOnUse'
          x1={110.365}
          y1={-195.041}
          x2={406.48}
          y2={-209.604}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M332.94 62.45v17.28c-.02.42-.25.82-.62 1.03l-60.46 34.9c-.98.57-2.21.57-3.19 0l-61.66-35.45c-.41-.23-.65-.68-.62-1.14v-17.2c0 .45.23.86.63 1.08l61.65 35.45c.98.58 2.21.58 3.19 0l60.45-34.9c.38-.22.62-.61.63-1.05z'
          fill='url(#banner_svg__SVGID_88_)'
        />
        <linearGradient
          id='banner_svg__SVGID_89_'
          gradientUnits='userSpaceOnUse'
          x1={349.689}
          y1={-235.941}
          x2={-278.316}
          y2={-175.019}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M332.31 61.32l-61.67-35.45a3.19 3.19 0 00-3.19 0L207 60.77c-.36.21-.59.58-.62.99v.11c0 .45.23.86.63 1.08l61.65 35.45c.98.58 2.21.58 3.19 0l60.45-34.9c.6-.36.8-1.14.44-1.74-.1-.18-.25-.33-.43-.44z'
          fill='url(#banner_svg__SVGID_89_)'
        />
        <linearGradient
          id='banner_svg__SVGID_90_'
          gradientUnits='userSpaceOnUse'
          x1={330.627}
          y1={-218.766}
          x2={279.164}
          y2={-186.113}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M280 102.96a.47.47 0 01-.4-.23.452.452 0 01.17-.61l40.01-23.08c.22-.12.49-.05.61.17.13.21.05.48-.15.6l-.01.01-40.01 23.07c-.07.06-.15.08-.22.07z'
          fill='url(#banner_svg__SVGID_90_)'
        />
        <linearGradient
          id='banner_svg__SVGID_91_'
          gradientUnits='userSpaceOnUse'
          x1={331.245}
          y1={-217.789}
          x2={279.782}
          y2={-185.145}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M323.43 77.91a.47.47 0 01-.4-.23c-.12-.22-.04-.5.18-.61h.01l.42-.23a.45.45 0 01.61.19c.11.23.03.49-.19.61l-.43.23c-.06.03-.13.05-.2.04z'
          fill='url(#banner_svg__SVGID_91_)'
        />
        <linearGradient
          id='banner_svg__SVGID_92_'
          gradientUnits='userSpaceOnUse'
          x1={331.341}
          y1={-217.636}
          x2={279.878}
          y2={-184.984}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M325.41 76.86c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.21-.14.49-.07.62.14.14.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.22c-.08.05-.15.06-.22.06z'
          fill='url(#banner_svg__SVGID_92_)'
        />
        <linearGradient
          id='banner_svg__SVGID_93_'
          gradientUnits='userSpaceOnUse'
          x1={331.409}
          y1={-217.534}
          x2={279.947}
          y2={-184.881}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M327.2 75.87c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.22c.21-.14.49-.07.62.14.14.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23a.57.57 0 01-.22.04z'
          fill='url(#banner_svg__SVGID_93_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_94_'
        gradientUnits='userSpaceOnUse'
        x1={379.254}
        y1={-223.163}
        x2={264.187}
        y2={-220.58}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M327.35 46.12v15.73c-.03.38-.24.71-.57.9l-55.06 31.79c-.9.51-2.01.51-2.91 0l-56.22-32.25c-.37-.22-.59-.62-.57-1.04V45.58c-.01.41.22.78.57.99l56.16 32.29c.9.52 2.01.52 2.91 0l55.06-31.8c.34-.19.57-.55.58-.95l.05.01z'
        fill='url(#banner_svg__SVGID_94_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_95_'
          gradientUnits='userSpaceOnUse'
          x1={110.365}
          y1={-220.53}
          x2={406.479}
          y2={-235.092}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M332.94 36.97v17.28c-.02.42-.25.81-.62 1.03l-60.46 34.9c-.98.57-2.21.57-3.19 0l-61.65-35.46c-.4-.23-.65-.67-.62-1.13v-17.2c-.01.45.23.87.63 1.09l61.65 35.45c.99.57 2.2.57 3.19 0l60.46-34.9c.38-.23.61-.63.61-1.06z'
          fill='url(#banner_svg__SVGID_95_)'
        />
        <linearGradient
          id='banner_svg__SVGID_96_'
          gradientUnits='userSpaceOnUse'
          x1={75.174}
          y1={-245.895}
          x2={349.959}
          y2={-256.866}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M332.93 36.91v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.7-22.03 12.7-18.21 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.02-12.66-20.22-11.63c-.4-.22-.64-.64-.63-1.09v-.1c.03-.41.26-.78.62-.99L267.47.39a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47.09.19.15.39.15.6z'
          fill='url(#banner_svg__SVGID_96_)'
        />
        <linearGradient
          id='banner_svg__SVGID_97_'
          gradientUnits='userSpaceOnUse'
          x1={330.625}
          y1={-244.248}
          x2={279.162}
          y2={-211.596}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M280 77.48a.47.47 0 01-.4-.23c-.12-.22-.04-.5.17-.62l40.01-23.07c.21-.13.48-.05.6.15l.01.01c.13.22.05.5-.16.62l-40.01 23.07a.32.32 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_97_)'
        />
        <linearGradient
          id='banner_svg__SVGID_98_'
          gradientUnits='userSpaceOnUse'
          x1={331.248}
          y1={-243.273}
          x2={279.785}
          y2={-210.62}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M323.43 52.43c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.22-.12.49-.04.61.18v.01c.11.22.03.49-.19.6l-.43.23c-.06.03-.13.06-.2.05z'
          fill='url(#banner_svg__SVGID_98_)'
        />
        <linearGradient
          id='banner_svg__SVGID_99_'
          gradientUnits='userSpaceOnUse'
          x1={331.311}
          y1={-243.163}
          x2={279.848}
          y2={-210.51}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M325.41 51.38c-.25.05-.49-.1-.55-.34-.05-.25.1-.5.34-.56l.42-.23c.23-.11.49-.03.61.19.12.23.03.5-.19.61l-.42.23c-.06.06-.14.09-.21.1z'
          fill='url(#banner_svg__SVGID_99_)'
        />
        <linearGradient
          id='banner_svg__SVGID_100_'
          gradientUnits='userSpaceOnUse'
          x1={331.389}
          y1={-243.045}
          x2={279.926}
          y2={-210.401}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M327.2 50.36a.45.45 0 01-.21-.85l.42-.23c.23-.11.49-.03.61.19.12.23.03.5-.19.61l-.42.23c-.06.03-.14.05-.21.05z'
          fill='url(#banner_svg__SVGID_100_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_101_'
        gradientUnits='userSpaceOnUse'
        x1={161.144}
        y1={-44.953}
        x2={466.016}
        y2={-59.947}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M389.28 284.49c-.03.42-.25.81-.62 1.03l-60.46 34.9c-.98.58-2.21.58-3.19 0l-61.65-35.45c-.4-.23-.65-.67-.62-1.13v-93.75-.1c.03-.41.26-.78.62-.99l60.45-34.9a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47v94.42l.16.05z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_101_)'
      />
      <linearGradient
        id='banner_svg__SVGID_102_'
        gradientUnits='userSpaceOnUse'
        x1={166.697}
        y1={9.716}
        x2={462.812}
        y2={-4.846}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M389.27 267.21v17.28c-.02.42-.25.81-.62 1.03l-60.45 34.9c-.98.58-2.21.58-3.19 0l-61.65-35.45c-.4-.23-.65-.67-.63-1.13v-17.21c-.01.45.23.86.63 1.09l61.65 35.45c.99.57 2.2.57 3.19 0l60.45-34.9c.38-.22.62-.62.62-1.06z'
        fill='url(#banner_svg__SVGID_102_)'
      />
      <linearGradient
        id='banner_svg__SVGID_103_'
        gradientUnits='userSpaceOnUse'
        x1={406.02}
        y1={-31.189}
        x2={-221.985}
        y2={29.724}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M388.64 266.07l-61.65-35.45a3.19 3.19 0 00-3.19 0l-60.45 34.9c-.36.21-.59.58-.63.99v.1c-.01.45.23.86.63 1.09L325 303.15c.99.57 2.2.57 3.19 0l60.45-34.9c.39-.22.62-.61.63-1.05.01-.46-.24-.89-.63-1.13z'
        fill='url(#banner_svg__SVGID_103_)'
      />
      <linearGradient
        id='banner_svg__SVGID_104_'
        gradientUnits='userSpaceOnUse'
        x1={386.983}
        y1={-13.966}
        x2={335.52}
        y2={18.687}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M336.35 307.76a.45.45 0 01-.23-.84l40.01-23.07c.21-.13.48-.05.6.15l.01.01c.13.22.05.49-.16.62l-40.01 23.07c-.07.04-.15.06-.22.06z'
        fill='url(#banner_svg__SVGID_104_)'
      />
      <linearGradient
        id='banner_svg__SVGID_105_'
        gradientUnits='userSpaceOnUse'
        x1={387.577}
        y1={-13.035}
        x2={336.114}
        y2={19.618}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M379.76 282.67c-.25 0-.45-.2-.45-.45 0-.16.09-.32.23-.4l.43-.23a.451.451 0 01.42.79l-.42.23c-.06.04-.14.07-.21.06z'
        fill='url(#banner_svg__SVGID_105_)'
      />
      <linearGradient
        id='banner_svg__SVGID_106_'
        gradientUnits='userSpaceOnUse'
        x1={387.685}
        y1={-12.854}
        x2={336.222}
        y2={19.798}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M381.74 281.66a.45.45 0 01-.21-.85l.42-.23c.22-.11.49-.03.6.19.12.22.04.49-.18.61h-.01l-.42.23c-.05.03-.13.06-.2.05z'
        fill='url(#banner_svg__SVGID_106_)'
      />
      <linearGradient
        id='banner_svg__SVGID_107_'
        gradientUnits='userSpaceOnUse'
        x1={387.731}
        y1={-12.773}
        x2={336.268}
        y2={19.871}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M383.53 280.64a.45.45 0 01-.21-.85l.42-.23c.23-.12.49-.04.61.19s.04.49-.19.61l-.43.23c-.05.03-.12.06-.2.05z'
        fill='url(#banner_svg__SVGID_107_)'
      />
      <linearGradient
        id='banner_svg__SVGID_108_'
        gradientUnits='userSpaceOnUse'
        x1={435.586}
        y1={-18.404}
        x2={320.519}
        y2={-15.812}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M383.63 250.87v15.74c-.03.38-.24.71-.57.9L328 299.31c-.9.52-2.01.52-2.91 0l-56.16-32.29c-.37-.21-.59-.61-.57-1.04v-15.64c0 .41.23.78.58.99l56.16 32.28c.9.52 2.01.52 2.91 0l55.06-31.79c.35-.19.56-.55.56-.95z'
        fill='url(#banner_svg__SVGID_108_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_109_'
          gradientUnits='userSpaceOnUse'
          x1={166.698}
          y1={-15.96}
          x2={462.812}
          y2={-30.523}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M389.27 241.53v17.28c-.02.42-.25.82-.62 1.03l-60.45 34.91c-.99.57-2.2.57-3.19 0l-61.65-35.45c-.4-.23-.66-.68-.63-1.14v-17.21c0 .45.24.86.63 1.09l61.65 35.44c.98.58 2.21.58 3.19 0l60.45-34.9c.38-.21.62-.62.62-1.05z'
          fill='url(#banner_svg__SVGID_109_)'
        />
        <linearGradient
          id='banner_svg__SVGID_110_'
          gradientUnits='userSpaceOnUse'
          x1={406.021}
          y1={-56.864}
          x2={-221.984}
          y2={4.058}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M388.64 240.4l-61.65-35.45a3.19 3.19 0 00-3.19 0l-60.45 34.9c-.36.21-.59.59-.63 1v.1c0 .45.24.86.63 1.09L325 277.48c.98.58 2.21.58 3.19 0l60.45-34.9c.6-.34.81-1.11.48-1.7-.12-.2-.29-.37-.48-.48z'
          fill='url(#banner_svg__SVGID_110_)'
        />
        <linearGradient
          id='banner_svg__SVGID_111_'
          gradientUnits='userSpaceOnUse'
          x1={386.966}
          y1={-39.679}
          x2={335.503}
          y2={-7.026}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M336.35 282.04a.45.45 0 01-.23-.84l40.01-23.07c.2-.14.49-.1.63.1s.1.49-.1.63c-.03.02-.05.04-.08.04l-40.01 23.07a.32.32 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_111_)'
        />
        <linearGradient
          id='banner_svg__SVGID_112_'
          gradientUnits='userSpaceOnUse'
          x1={387.577}
          y1={-38.713}
          x2={336.114}
          y2={-6.06}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M379.76 256.99a.466.466 0 01-.44-.48c.01-.15.09-.3.23-.38l.43-.23c.22-.11.49-.03.6.19s.04.49-.18.61h-.01l-.42.23c-.06.04-.14.07-.21.06z'
          fill='url(#banner_svg__SVGID_112_)'
        />
        <linearGradient
          id='banner_svg__SVGID_113_'
          gradientUnits='userSpaceOnUse'
          x1={387.666}
          y1={-38.561}
          x2={336.203}
          y2={-5.918}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M381.74 255.94c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.22c.23-.09.5.03.58.26.07.2.01.41-.16.53l-.42.23c-.06.02-.14.05-.21.04z'
          fill='url(#banner_svg__SVGID_113_)'
        />
        <linearGradient
          id='banner_svg__SVGID_114_'
          gradientUnits='userSpaceOnUse'
          x1={387.735}
          y1={-38.453}
          x2={336.272}
          y2={-5.8}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M383.53 254.95c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.21-.13.49-.07.62.14.14.21.07.49-.14.62-.02.01-.04.02-.05.03l-.43.23c-.06.03-.13.06-.21.05z'
          fill='url(#banner_svg__SVGID_114_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_115_'
        gradientUnits='userSpaceOnUse'
        x1={435.586}
        y1={-44.08}
        x2={320.519}
        y2={-41.488}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M383.63 225.2v15.74c-.03.38-.24.71-.57.9l-55.07 31.82c-.9.51-2.01.51-2.91 0l-56.16-32.29c-.37-.22-.58-.61-.57-1.04v-15.67c0 .41.22.79.58.99l56.16 32.29c.9.52 2.01.52 2.91 0l55.06-31.79c.35-.19.55-.56.57-.95z'
        fill='url(#banner_svg__SVGID_115_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_116_'
          gradientUnits='userSpaceOnUse'
          x1={166.697}
          y1={-41.345}
          x2={462.812}
          y2={-55.908}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M389.27 216.15v17.28c-.02.42-.25.82-.62 1.03l-60.45 34.9c-.98.57-2.21.57-3.19 0l-61.65-35.45c-.4-.23-.66-.68-.63-1.14v-17.2c0 .45.23.86.63 1.08l61.65 35.45c.98.58 2.21.58 3.19 0l60.45-34.9c.37-.21.62-.62.62-1.05z'
          fill='url(#banner_svg__SVGID_116_)'
        />
        <linearGradient
          id='banner_svg__SVGID_117_'
          gradientUnits='userSpaceOnUse'
          x1={406.025}
          y1={-82.204}
          x2={-221.98}
          y2={-21.281}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M388.64 215.06l-61.65-35.45c-.98-.58-2.21-.58-3.19 0l-60.45 34.9c-.36.21-.59.58-.63.99v.11c0 .45.23.86.63 1.08L325 252.14c.98.58 2.21.58 3.19 0l60.45-34.9c.6-.34.81-1.11.48-1.7-.12-.2-.29-.36-.48-.48z'
          fill='url(#banner_svg__SVGID_117_)'
        />
        <linearGradient
          id='banner_svg__SVGID_118_'
          gradientUnits='userSpaceOnUse'
          x1={386.962}
          y1={-65.065}
          x2={335.499}
          y2={-32.412}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M336.35 256.66a.442.442 0 01-.46-.44c0-.17.09-.32.23-.41l40.01-23.07c.22-.12.49-.04.61.17.13.21.05.48-.15.6l-.01.01-40.01 23.07a.32.32 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_118_)'
        />
        <linearGradient
          id='banner_svg__SVGID_119_'
          gradientUnits='userSpaceOnUse'
          x1={387.577}
          y1={-64.093}
          x2={336.115}
          y2={-31.449}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M379.76 231.6a.47.47 0 01-.4-.23.447.447 0 01.18-.61l.43-.23a.42.42 0 01.59.18s0 .01.01.01c.12.22.04.49-.18.61h-.01l-.42.23c-.06.03-.13.04-.2.04z'
          fill='url(#banner_svg__SVGID_119_)'
        />
        <linearGradient
          id='banner_svg__SVGID_120_'
          gradientUnits='userSpaceOnUse'
          x1={387.69}
          y1={-63.913}
          x2={336.227}
          y2={-31.26}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M381.74 230.56a.434.434 0 01-.4-.2.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.23-.09.5.03.58.26.07.2.01.41-.16.53l-.42.23c-.07.03-.14.03-.21.01z'
          fill='url(#banner_svg__SVGID_120_)'
        />
        <linearGradient
          id='banner_svg__SVGID_121_'
          gradientUnits='userSpaceOnUse'
          x1={387.734}
          y1={-63.835}
          x2={336.271}
          y2={-31.192}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M383.53 229.57c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.22-.12.49-.04.61.18v.01c.11.22.03.49-.19.6l-.43.22c-.05.04-.12.07-.2.06z'
          fill='url(#banner_svg__SVGID_121_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_122_'
        gradientUnits='userSpaceOnUse'
        x1={435.586}
        y1={-69.45}
        x2={320.519}
        y2={-66.867}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M383.63 199.81v15.73c-.03.38-.24.71-.57.9l-55.07 31.82c-.9.51-2.01.51-2.91 0l-56.16-32.31c-.37-.22-.58-.62-.57-1.04v-15.64c0 .41.22.79.58.99l56.16 32.29c.9.52 2.01.52 2.91 0l55.06-31.8c.35-.18.56-.54.57-.94z'
        fill='url(#banner_svg__SVGID_122_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_123_'
          gradientUnits='userSpaceOnUse'
          x1={166.697}
          y1={-66.835}
          x2={462.811}
          y2={-81.398}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M389.27 190.66v17.28c-.02.42-.25.81-.62 1.03l-60.46 34.89c-.98.58-2.21.58-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.63-1.13v-17.2c-.01.45.23.87.63 1.09L325 226.62c.99.57 2.2.57 3.19 0l60.45-34.91c.37-.21.62-.61.63-1.05z'
          fill='url(#banner_svg__SVGID_123_)'
        />
        <linearGradient
          id='banner_svg__SVGID_124_'
          gradientUnits='userSpaceOnUse'
          x1={131.498}
          y1={-92.181}
          x2={406.291}
          y2={-103.152}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M389.27 190.61v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.7-22.03 12.72-18.21 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.02-12.66-20.22-11.63c-.4-.22-.64-.64-.63-1.09v-.1c.03-.41.26-.78.62-.99l60.45-34.9a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47.08.17.15.38.16.58z'
          fill='url(#banner_svg__SVGID_124_)'
        />
        <linearGradient
          id='banner_svg__SVGID_125_'
          gradientUnits='userSpaceOnUse'
          x1={386.965}
          y1={-90.54}
          x2={335.502}
          y2={-57.897}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M336.35 231.17a.45.45 0 01-.23-.84l40.01-23.07c.21-.13.48-.05.6.15l.01.01c.13.22.05.5-.16.62l-40.01 23.07c-.07.04-.15.06-.22.06z'
          fill='url(#banner_svg__SVGID_125_)'
        />
        <linearGradient
          id='banner_svg__SVGID_126_'
          gradientUnits='userSpaceOnUse'
          x1={387.577}
          y1={-89.581}
          x2={336.114}
          y2={-56.929}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M379.76 206.12c-.25 0-.45-.2-.45-.45 0-.16.09-.32.23-.4l.43-.23a.451.451 0 01.42.79l-.42.23c-.06.05-.14.07-.21.06z'
          fill='url(#banner_svg__SVGID_126_)'
        />
        <linearGradient
          id='banner_svg__SVGID_127_'
          gradientUnits='userSpaceOnUse'
          x1={387.668}
          y1={-89.427}
          x2={336.206}
          y2={-56.774}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M381.74 205.08a.45.45 0 01-.21-.85l.42-.22c.22-.11.49-.03.6.19.12.22.04.5-.18.61h-.01l-.42.23c-.05.02-.13.05-.2.04z'
          fill='url(#banner_svg__SVGID_127_)'
        />
        <linearGradient
          id='banner_svg__SVGID_128_'
          gradientUnits='userSpaceOnUse'
          x1={387.734}
          y1={-89.324}
          x2={336.271}
          y2={-56.671}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M383.53 204.08a.47.47 0 01-.4-.23c-.12-.22-.04-.5.18-.61h.01l.42-.23c.23-.12.49-.03.61.19.11.23.03.49-.19.61l-.43.23c-.06.03-.12.05-.2.04z'
          fill='url(#banner_svg__SVGID_128_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_129_'
        gradientUnits='userSpaceOnUse'
        x1={19.419}
        y1={-68.102}
        x2={324.281}
        y2={-83.096}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M247.54 261.34c-.03.42-.25.81-.62 1.03l-60.46 34.9c-.98.58-2.21.58-3.19 0l-61.66-35.41c-.41-.23-.65-.67-.62-1.13v-93.8-.1c.03-.41.26-.78.62-.99l60.45-34.9a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47v94.43l.17.05z'
        opacity={0.2}
        fill='url(#banner_svg__SVGID_129_)'
      />
      <linearGradient
        id='banner_svg__SVGID_130_'
        gradientUnits='userSpaceOnUse'
        x1={24.962}
        y1={-13.436}
        x2={321.077}
        y2={-27.998}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M247.54 244.06v17.28c-.03.42-.25.81-.62 1.03l-60.46 34.9c-.99.57-2.2.57-3.19 0l-61.66-35.41c-.41-.23-.65-.67-.62-1.13v-17.25c0 .45.23.86.63 1.09l61.65 35.44c.98.58 2.21.58 3.19 0l60.46-34.9c.38-.21.61-.61.62-1.05z'
        fill='url(#banner_svg__SVGID_130_)'
      />
      <linearGradient
        id='banner_svg__SVGID_131_'
        gradientUnits='userSpaceOnUse'
        x1={264.289}
        y1={-54.304}
        x2={-363.716}
        y2={6.619}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M246.91 242.96l-61.66-35.45a3.19 3.19 0 00-3.19 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c0 .45.23.86.63 1.09l61.65 35.44c.98.58 2.21.58 3.19 0l60.46-34.9c.38-.22.61-.61.62-1.04.01-.46-.23-.89-.63-1.13z'
        fill='url(#banner_svg__SVGID_131_)'
      />
      <linearGradient
        id='banner_svg__SVGID_132_'
        gradientUnits='userSpaceOnUse'
        x1={245.225}
        y1={-37.151}
        x2={193.762}
        y2={-4.507}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M194.59 284.57a.47.47 0 01-.4-.23.452.452 0 01.17-.61l40.01-23.07c.21-.13.48-.05.6.15l.01.01c.13.22.05.5-.16.62l-40.01 23.07c-.06.04-.15.06-.22.06z'
        fill='url(#banner_svg__SVGID_132_)'
      />
      <linearGradient
        id='banner_svg__SVGID_133_'
        gradientUnits='userSpaceOnUse'
        x1={245.846}
        y1={-36.179}
        x2={194.383}
        y2={-3.526}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M238.03 259.52c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.22-.12.49-.04.6.17v.01c.12.22.04.49-.18.61l-.43.23c-.06.03-.13.06-.2.05z'
        fill='url(#banner_svg__SVGID_133_)'
      />
      <linearGradient
        id='banner_svg__SVGID_134_'
        gradientUnits='userSpaceOnUse'
        x1={245.944}
        y1={-36.012}
        x2={194.481}
        y2={-3.369}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M240.04 258.47a.47.47 0 01-.4-.23.447.447 0 01.18-.61h.01l.42-.23c.23-.12.5-.04.61.19s.04.49-.19.61l-.42.23c-.06.03-.13.05-.21.04z'
        fill='url(#banner_svg__SVGID_134_)'
      />
      <linearGradient
        id='banner_svg__SVGID_135_'
        gradientUnits='userSpaceOnUse'
        x1={246.019}
        y1={-35.909}
        x2={194.556}
        y2={-3.256}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#fbd242' />
        <stop offset={1} stopColor='#f56329' />
      </linearGradient>
      <path
        d='M241.84 257.48a.47.47 0 01-.4-.23.447.447 0 01.18-.61h.01l.42-.23c.23-.12.5-.04.61.19s.04.49-.19.61l-.42.23c-.06.03-.13.05-.21.04z'
        fill='url(#banner_svg__SVGID_135_)'
      />
      <linearGradient
        id='banner_svg__SVGID_136_'
        gradientUnits='userSpaceOnUse'
        x1={293.851}
        y1={-41.552}
        x2={178.784}
        y2={-38.96}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M241.9 227.72v15.74c-.03.38-.24.71-.57.9l-55.06 31.8c-.9.52-2.01.52-2.91 0l-56.17-32.3c-.37-.21-.59-.61-.57-1.04v-15.63c0 .4.22.78.57.99l56.15 32.28c.9.52 2.01.52 2.91 0l55.06-31.79c.34-.18.56-.54.59-.95z'
        fill='url(#banner_svg__SVGID_136_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_137_'
          gradientUnits='userSpaceOnUse'
          x1={24.962}
          y1={-39.136}
          x2={321.076}
          y2={-53.698}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M247.54 218.38v17.28c-.02.42-.25.81-.62 1.03l-60.47 34.89c-.98.57-2.21.57-3.19 0l-61.65-35.45c-.41-.23-.66-.68-.62-1.14v-17.23c0 .45.23.86.63 1.08l61.65 35.45c.98.58 2.21.58 3.19 0l60.46-34.9c.38-.22.61-.61.62-1.04v.03z'
          fill='url(#banner_svg__SVGID_137_)'
        />
        <linearGradient
          id='banner_svg__SVGID_138_'
          gradientUnits='userSpaceOnUse'
          x1={264.283}
          y1={-80.048}
          x2={-363.722}
          y2={-19.126}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M246.91 217.25l-61.66-35.49a3.19 3.19 0 00-3.19 0l-60.45 34.9c-.36.21-.59.58-.62.99v.11c0 .45.23.86.63 1.08l61.65 35.45c.98.58 2.21.58 3.19 0l60.46-34.9a1.27 1.27 0 000-2.18l-.01.04z'
          fill='url(#banner_svg__SVGID_138_)'
        />
        <linearGradient
          id='banner_svg__SVGID_139_'
          gradientUnits='userSpaceOnUse'
          x1={245.224}
          y1={-62.838}
          x2={193.761}
          y2={-30.185}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M194.59 258.89c-.16 0-.32-.09-.4-.22a.452.452 0 01.17-.61l40.01-23.08c.22-.12.49-.04.61.17.13.21.05.48-.15.6l-.01.01-40.01 23.07a.35.35 0 01-.22.06z'
          fill='url(#banner_svg__SVGID_139_)'
        />
        <linearGradient
          id='banner_svg__SVGID_140_'
          gradientUnits='userSpaceOnUse'
          x1={245.843}
          y1={-61.861}
          x2={194.38}
          y2={-29.218}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M238.03 233.84a.47.47 0 01-.4-.23.447.447 0 01.18-.61h.01l.42-.23c.22-.11.49-.03.6.19.12.22.04.49-.18.61l-.43.23c-.07.03-.13.04-.2.04z'
          fill='url(#banner_svg__SVGID_140_)'
        />
        <linearGradient
          id='banner_svg__SVGID_141_'
          gradientUnits='userSpaceOnUse'
          x1={245.947}
          y1={-61.694}
          x2={194.484}
          y2={-29.042}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M240.04 232.79c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.22-.12.5-.04.61.18v.01c.11.22.03.49-.19.6l-.42.23c-.06.03-.13.05-.21.05z'
          fill='url(#banner_svg__SVGID_141_)'
        />
        <linearGradient
          id='banner_svg__SVGID_142_'
          gradientUnits='userSpaceOnUse'
          x1={246.02}
          y1={-61.585}
          x2={194.557}
          y2={-28.933}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M241.84 231.8c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.07.03-.14.05-.22.05z'
          fill='url(#banner_svg__SVGID_142_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_143_'
        gradientUnits='userSpaceOnUse'
        x1={293.851}
        y1={-67.245}
        x2={178.784}
        y2={-64.662}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M241.9 202.05v15.71c-.03.38-.24.71-.57.9l-55.06 31.79c-.9.51-2.01.51-2.91 0l-56.16-32.29c-.37-.22-.59-.62-.57-1.04v-15.61c-.01.4.22.78.57.99l56.14 32.29c.9.52 2.01.52 2.91 0l55.06-31.8c.34-.19.56-.55.59-.94z'
        fill='url(#banner_svg__SVGID_143_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_144_'
          gradientUnits='userSpaceOnUse'
          x1={24.962}
          y1={-64.503}
          x2={321.077}
          y2={-79.065}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M247.54 193v17.28c-.02.42-.25.81-.62 1.03l-60.47 34.89c-.98.57-2.21.57-3.19 0l-61.65-35.45c-.4-.23-.65-.68-.62-1.14v-17.2c-.01.45.23.87.63 1.09l61.65 35.45c.99.57 2.2.57 3.19 0l60.46-34.9c.37-.21.61-.61.62-1.05z'
          fill='url(#banner_svg__SVGID_144_)'
        />
        <linearGradient
          id='banner_svg__SVGID_145_'
          gradientUnits='userSpaceOnUse'
          x1={264.285}
          y1={-105.404}
          x2={-363.72}
          y2={-44.491}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={1} stopColor='#003cff' />
        </linearGradient>
        <path
          d='M246.91 191.86l-61.66-35.45a3.19 3.19 0 00-3.19 0l-60.45 34.9c-.36.21-.59.58-.62.99v.1c-.01.45.23.87.63 1.09l61.65 35.45c.99.57 2.2.57 3.19 0l60.46-34.9c.38-.22.61-.61.62-1.04.02-.47-.22-.91-.63-1.14z'
          fill='url(#banner_svg__SVGID_145_)'
        />
        <linearGradient
          id='banner_svg__SVGID_146_'
          gradientUnits='userSpaceOnUse'
          x1={245.222}
          y1={-88.221}
          x2={193.759}
          y2={-55.569}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M194.59 233.51a.47.47 0 01-.4-.23.467.467 0 01.17-.62l40.01-23.07c.21-.13.48-.05.6.15l.01.01c.13.22.05.5-.16.62l-40.01 23.07a.36.36 0 01-.22.07z'
          fill='url(#banner_svg__SVGID_146_)'
        />
        <linearGradient
          id='banner_svg__SVGID_147_'
          gradientUnits='userSpaceOnUse'
          x1={245.847}
          y1={-87.244}
          x2={194.384}
          y2={-54.591}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M238.03 208.45c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23a.42.42 0 01.59.18s0 .01.01.01c.12.22.04.49-.17.6h-.01l-.43.23c-.06.04-.13.06-.2.05z'
          fill='url(#banner_svg__SVGID_147_)'
        />
        <linearGradient
          id='banner_svg__SVGID_148_'
          gradientUnits='userSpaceOnUse'
          x1={245.944}
          y1={-87.08}
          x2={194.481}
          y2={-54.427}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M240.04 207.41a.45.45 0 01-.21-.85l.42-.22c.22-.12.49-.04.61.18.11.22.03.49-.19.61l-.42.23c-.06.03-.13.05-.21.05z'
          fill='url(#banner_svg__SVGID_148_)'
        />
        <linearGradient
          id='banner_svg__SVGID_149_'
          gradientUnits='userSpaceOnUse'
          x1={245.992}
          y1={-87.01}
          x2={194.529}
          y2={-54.367}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M241.84 206.42c-.25.05-.49-.1-.55-.34-.05-.25.1-.5.34-.56l.42-.22c.21-.13.49-.07.62.14.13.21.07.49-.14.62-.02.01-.04.02-.05.03l-.42.23c-.06.06-.14.09-.22.1z'
          fill='url(#banner_svg__SVGID_149_)'
        />
      </g>
      <linearGradient
        id='banner_svg__SVGID_150_'
        gradientUnits='userSpaceOnUse'
        x1={293.851}
        y1={-92.613}
        x2={178.784}
        y2={-90.03}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={1} stopColor='#003cff' />
      </linearGradient>
      <path
        d='M241.9 176.66v15.74c-.03.38-.24.71-.57.9l-55.06 31.79c-.9.52-2.01.52-2.91 0L127.2 192.8c-.37-.21-.59-.61-.57-1.04v-15.63c0 .41.22.78.57.99l56.14 32.29c.9.51 2.01.51 2.91 0l55.06-31.8c.34-.19.56-.55.59-.95z'
        fill='url(#banner_svg__SVGID_150_)'
      />
      <g>
        <linearGradient
          id='banner_svg__SVGID_151_'
          gradientUnits='userSpaceOnUse'
          x1={24.962}
          y1={-89.982}
          x2={321.077}
          y2={-104.544}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M247.54 167.51v17.28c-.03.42-.25.81-.62 1.03l-60.46 34.9c-.99.57-2.2.57-3.19 0l-61.65-35.45c-.41-.23-.65-.67-.62-1.13v-17.21c0 .45.23.86.63 1.09l61.65 35.44c.98.58 2.21.58 3.19 0l60.46-34.9c.38-.22.61-.61.61-1.05z'
          fill='url(#banner_svg__SVGID_151_)'
        />
        <linearGradient
          id='banner_svg__SVGID_152_'
          gradientUnits='userSpaceOnUse'
          x1={-10.227}
          y1={-115.329}
          x2={264.557}
          y2={-126.301}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#007aff' />
          <stop offset={0.5} stopColor='#009cff' />
          <stop offset={1} stopColor='#d9eaff' />
        </linearGradient>
        <path
          d='M247.53 167.46v.05a1.194 1.194 0 01-.62 1.05l-20.22 11.7-22.03 12.72-18.21 10.51c-.99.57-2.2.57-3.19 0l-19.4-11.16-22.02-12.66-20.22-11.63c-.4-.22-.64-.64-.63-1.09v-.1c.03-.41.26-.78.62-.99l60.45-34.9a3.19 3.19 0 013.19 0l61.66 35.45c.2.11.35.27.46.47.09.17.15.38.16.58z'
          fill='url(#banner_svg__SVGID_152_)'
        />
        <linearGradient
          id='banner_svg__SVGID_153_'
          gradientUnits='userSpaceOnUse'
          x1={245.224}
          y1={-113.698}
          x2={193.761}
          y2={-81.054}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M194.59 208.02a.47.47 0 01-.4-.23.452.452 0 01.17-.61l40.01-23.07c.2-.14.49-.1.63.1s.1.49-.1.63c-.03.02-.05.04-.08.04l-40.01 23.08c-.06.04-.15.06-.22.06z'
          fill='url(#banner_svg__SVGID_153_)'
        />
        <linearGradient
          id='banner_svg__SVGID_154_'
          gradientUnits='userSpaceOnUse'
          x1={245.846}
          y1={-112.725}
          x2={194.383}
          y2={-80.072}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M238.03 182.98c-.17 0-.32-.1-.4-.24a.42.42 0 01.18-.59s.01 0 .01-.01l.42-.23c.22-.12.49-.04.6.17v.01c.12.22.04.5-.18.61l-.43.23c-.06.03-.13.05-.2.05z'
          fill='url(#banner_svg__SVGID_154_)'
        />
        <linearGradient
          id='banner_svg__SVGID_155_'
          gradientUnits='userSpaceOnUse'
          x1={245.944}
          y1={-112.559}
          x2={194.481}
          y2={-79.915}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M240.04 181.92a.47.47 0 01-.4-.23c-.12-.22-.04-.5.18-.61h.01l.42-.22c.23-.12.5-.04.61.19.12.23.04.5-.19.61l-.42.23c-.06.02-.13.04-.21.03z'
          fill='url(#banner_svg__SVGID_155_)'
        />
        <linearGradient
          id='banner_svg__SVGID_156_'
          gradientUnits='userSpaceOnUse'
          x1={246.019}
          y1={-112.455}
          x2={194.556}
          y2={-79.803}
          gradientTransform='translate(0 290.315)'
        >
          <stop offset={0} stopColor='#fbd242' />
          <stop offset={1} stopColor='#f56329' />
        </linearGradient>
        <path
          d='M241.84 180.93a.47.47 0 01-.4-.23c-.12-.22-.04-.5.18-.61h.01l.42-.23c.23-.12.5-.04.61.19s.04.5-.19.61l-.42.22c-.06.04-.13.05-.21.05z'
          fill='url(#banner_svg__SVGID_156_)'
        />
      </g>
    </g>
    <g>
      <linearGradient
        id='banner_svg__SVGID_157_'
        gradientUnits='userSpaceOnUse'
        x1={358.426}
        y1={51.257}
        x2={406.721}
        y2={43.417}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M379.61 335.4l-3.37-1.94a.426.426 0 00-.41 0l-4.45 2.57-6.09.46v3.15c.01.05.04.11.08.13l7.95 4.59c.13.07.29.07.41 0l10.46-6c.04-.03.08-.07.08-.13v-3.16l-4.66.33z'
        fill='url(#banner_svg__SVGID_157_)'
      />
      <linearGradient
        id='banner_svg__SVGID_158_'
        gradientUnits='userSpaceOnUse'
        x1={350.246}
        y1={51.712}
        x2={388.839}
        y2={41.856}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M365.28 336.49c.01.05.04.1.08.13l7.95 4.59c.13.07.29.07.41 0l10.47-6.03c.04-.03.08-.08.08-.14 0-.05-.03-.11-.08-.13l-7.95-4.59a.426.426 0 00-.41 0l-10.47 6.03c-.05.03-.08.09-.08.14z'
        fill='url(#banner_svg__SVGID_158_)'
      />
      <path
        className='banner_svg__st4'
        d='M369.38 335.93c0 .03.02.05.04.07l4.5 2.57c.07.04.16.04.23 0l5.87-3.38c.03-.02.04-.04.05-.07-.01-.03-.03-.05-.05-.07l-4.46-2.57a.195.195 0 00-.23 0l-5.88 3.38c-.03.02-.04.04-.04.08l-.03-.01z'
      />
      <linearGradient
        id='banner_svg__SVGID_159_'
        gradientUnits='userSpaceOnUse'
        x1={374.798}
        y1={-7.893}
        x2={374.204}
        y2={185.62}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M380 273.11l-4.46-2.57a.235.235 0 00-.23 0l-5.88 3.39a.09.09 0 00-.04.08c0 .34-.27 61.76 0 61.91 0 .03.02.05.04.07l4.5 2.57c.07.05.16.05.23 0l5.87-3.38c.03-.02.04-.04.05-.07V273.2c0-.04-.04-.07-.08-.09z'
        fill='url(#banner_svg__SVGID_159_)'
      />
      <linearGradient
        id='banner_svg__SVGID_160_'
        gradientUnits='userSpaceOnUse'
        x1={374.958}
        y1={-9.593}
        x2={373.86}
        y2={90.552}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M377.11 277.93l-2.06-1.22a.078.078 0 00-.11 0l-2.7 1.56v57.41l2.06 1.19c.04.02.07.02.11 0l2.7-1.57v-57.37z'
        opacity={0.5}
        fill='url(#banner_svg__SVGID_160_)'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_161_'
      gradientUnits='userSpaceOnUse'
      x1={51.58}
      y1={-41.274}
      x2={51.58}
      y2={-262.993}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' stopOpacity={0.8} />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.6} />
      <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
    </linearGradient>
    <path
      d='M51.58 4.46c-13.43 0-24.3 7.05-24.3 15.72V132.17c.67 8.3 11.29 14.89 24.3 14.89s23.63-6.59 24.3-14.89V20.2c.03-8.68-10.86-15.74-24.3-15.74z'
      opacity={0.5}
      fill='url(#banner_svg__SVGID_161_)'
    />
    <g>
      <linearGradient
        id='banner_svg__SVGID_162_'
        gradientUnits='userSpaceOnUse'
        x1={88.583}
        y1={-232.245}
        x2={12.127}
        y2={-204.173}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M72.31 70.05v13.39c0 5.75-2.36 10.13-6.44 12.45l-.08.05-2.97 1.68v-.47c-1.84.48-3.74.67-5.63.55-1.34-.07-2.67-.25-3.99-.55-11.58-10.8-19.11-25.49-19.11-35.78v-9.59l-2.99-2.11 2.99-1.7 19.11 4.28c.37.01 19.11 17.8 19.11 17.8z'
        fill='url(#banner_svg__SVGID_162_)'
      />
      <linearGradient
        id='banner_svg__SVGID_163_'
        gradientUnits='userSpaceOnUse'
        x1={69.077}
        y1={-221.386}
        x2={59.374}
        y2={-170.815}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M72.31 70.05v13.39c0 5.75-2.36 10.13-6.44 12.45l-.08.05-2.97 1.68v-.47c-1.84.48-3.74.67-5.63.55l5.31-23.34 6.81-2.6 3-1.71z'
        fill='url(#banner_svg__SVGID_163_)'
      />
      <linearGradient
        id='banner_svg__SVGID_164_'
        gradientUnits='userSpaceOnUse'
        x1={-12.602}
        y1={-166.582}
        x2={69.515}
        y2={-235.415}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M50.21 53.96L31.1 49.68v13.38c0 10.31 7.53 24.95 19.11 35.76 11.63 2.57 19.11-3.39 19.11-13.69V71.75L50.21 53.96z'
        fill='url(#banner_svg__SVGID_164_)'
      />
      <linearGradient
        id='banner_svg__SVGID_165_'
        gradientUnits='userSpaceOnUse'
        x1={39.407}
        y1={-208.129}
        x2={126.979}
        y2={-296.376}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M66.42 72.17v11.36c.13 3.12-.92 6.17-2.94 8.56-2.79 3.09-7.42 4.35-13.27 3.05-9.83-9.17-16.2-21.6-16.2-30.33V53.44l.75.17 15.46 3.46 16.2 15.1z'
        fill='url(#banner_svg__SVGID_165_)'
      />
      <linearGradient
        id='banner_svg__SVGID_166_'
        gradientUnits='userSpaceOnUse'
        x1={73.788}
        y1={-226.005}
        x2={-13.928}
        y2={-194.235}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M66.42 72.17v11.36c.13 3.12-.92 6.17-2.94 8.56-2.84 2.53-7.2 3.49-12.52 2.3-9.83-9.16-16.2-21.6-16.2-30.33V53.62l15.46 3.46.75.69 15.45 14.4z'
        fill='url(#banner_svg__SVGID_166_)'
      />
      <linearGradient
        id='banner_svg__SVGID_167_'
        gradientUnits='userSpaceOnUse'
        x1={53.493}
        y1={-236.903}
        x2={14.874}
        y2={-245.219}
        gradientTransform='translate(0 290.315)'
      >
        <stop offset={0} stopColor='#007aff' />
        <stop offset={0.5} stopColor='#009cff' />
        <stop offset={1} stopColor='#d9eaff' />
      </linearGradient>
      <path
        d='M50.21 53.96l2.99-1.69-19.11-4.28s-3.28 1.8-2.99 1.7c.29-.11 19.11 4.27 19.11 4.27z'
        fill='url(#banner_svg__SVGID_167_)'
      />
    </g>
    <g opacity={0.6}>
      <path
        className='banner_svg__st1'
        d='M51.58 131.56c-13.19 0-24.05-6.72-24.73-15.3 0-.31-.05-.59-.05-.9 0-8.92 11.12-16.2 24.78-16.2s24.78 7.25 24.78 16.2v.86c-.73 8.61-11.6 15.34-24.78 15.34zm0-31.45c-13.17 0-23.88 6.85-23.88 15.3-.01.25-.01.51 0 .77.66 8.15 11.12 14.51 23.84 14.51s23.18-6.35 23.84-14.47v-.8c.08-8.46-10.64-15.31-23.8-15.31z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M51.58 65.66c-13.14 0-24.05-6.71-24.73-15.3 0-.3-.05-.59-.05-.9 0-8.91 11.12-16.2 24.78-16.2s24.78 7.26 24.78 16.2v.86c-.73 8.62-11.6 15.34-24.78 15.34zm0-31.45c-13.17 0-23.88 6.86-23.88 15.3-.02.25-.02.51 0 .77.69 8.13 11.16 14.48 23.88 14.48s23.18-6.35 23.84-14.47v-.8c.04-8.42-10.68-15.28-23.84-15.28z'
      />
    </g>
    <g className='banner_svg__st0'>
      <path
        className='banner_svg__st1'
        d='M72.24 85.17c0 .31-.07.74-.13 1.13 2.07 2.07 3.27 4.85 3.34 7.78v.8c-.65 8.1-11.12 14.47-23.84 14.47S28.43 103 27.78 94.85c-.02-.25-.02-.51 0-.77 0-4.91 3.65-9.3 9.31-12.08l-.43-.78c-5.94 2.95-9.77 7.62-9.77 12.86v.9c.69 8.58 11.56 15.3 24.73 15.3s24.05-6.72 24.74-15.3v-.9c-.1-3.4-1.59-6.62-4.12-8.91z'
      />
    </g>
    <g opacity={0.4}>
      <path
        className='banner_svg__st1'
        d='M58.77 57.31l1.27 1.2c9 2.19 15.4 7.77 15.4 14.27v.8c-.65 8.1-11.12 14.47-23.84 14.47S28.4 81.7 27.75 73.54c-.02-.25-.02-.51 0-.77.09-3 1.34-5.84 3.49-7.93 0-.29-.05-.69-.06-1.18-2.7 2.6-4.32 5.73-4.32 9.11v.9c.69 8.59 11.56 15.3 24.73 15.3s24.05-6.71 24.74-15.3v-.9c.03-7.27-7.39-13.41-17.56-15.46z'
      />
    </g>
    <linearGradient
      id='banner_svg__SVGID_168_'
      gradientUnits='userSpaceOnUse'
      x1={5.833}
      y1={28.116}
      x2={158.728}
      y2={-17.074}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M119.44 298.53c0 .14-.04.29-.12.41-.07.14-.19.27-.33.34l-43.65 25.25c-.71.41-1.59.41-2.3 0l-44.5-25.6a.903.903 0 01-.46-.79v-.07c.01-.08.04-.16.07-.23l1.44-3.47.34-.85 1.89-4.55 11.48-.12 28.87-16.68c.71-.41 1.59-.41 2.3 0l27.59 15.86 3.25-.04 10.34 1.19 1.97 4.77.34.85 1.44 3.48c.02.09.03.17.04.25z'
      fill='url(#banner_svg__SVGID_168_)'
    />
    <path
      className='banner_svg__st40'
      d='M117.97 294.77l-42.4 24.48c-.86.5-1.9.5-2.75 0l-43.26-24.88.34-.85 43.37 24.94c.58.33 1.28.33 1.85 0l42.53-24.54.32.85z'
    />
    <linearGradient
      id='banner_svg__SVGID_169_'
      gradientUnits='userSpaceOnUse'
      x1={-55.374}
      y1={4.205}
      x2={127.06}
      y2={-3.077}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M115.76 289.56c0 .13-.04.26-.1.38-.08.13-.19.23-.32.32L101.92 298l-14.63 8.45-12.09 6.98c-.66.38-1.46.38-2.12 0l-12.88-7.37-14.63-8.41-13.45-7.72a.788.788 0 01-.41-.72v-.07c.02-.27.17-.51.41-.66l40.14-23.17c.66-.38 1.46-.38 2.12 0l40.96 23.55c.13.07.23.18.31.32.07.11.1.25.11.38z'
      fill='url(#banner_svg__SVGID_169_)'
    />
    <linearGradient
      id='banner_svg__SVGID_170_'
      gradientUnits='userSpaceOnUse'
      x1={34.934}
      y1={10.322}
      x2={126.61}
      y2={-16.255}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M96.58 285.09l-22.36-12.85c-.47-.27-1.04-.27-1.51 0L50.79 284.9h-6.94v4.37c-.01.22.11.41.3.51l9.59 5.47 10.4 5.99 9.17 5.27c.47.27 1.04.27 1.5 0l8.6-4.97 10.41-6.01 9.56-5.52c.09-.05.17-.13.23-.23.05-.08.07-.17.07-.27v-4.4l-7.1-.02z'
      fill='url(#banner_svg__SVGID_170_)'
    />
    <linearGradient
      id='banner_svg__SVGID_171_'
      gradientUnits='userSpaceOnUse'
      x1={-66.947}
      y1={0.298}
      x2={105.055}
      y2={-6.569}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M103.65 285.13c0 .1-.03.19-.07.28-.05.09-.14.16-.23.22l-9.56 5.53-10.41 6-8.6 4.97c-.47.27-1.04.27-1.5 0l-9.17-5.27-10.37-5.97-9.56-5.49c-.19-.11-.3-.3-.3-.51v-.05c.01-.2.12-.37.29-.47l28.57-16.49a1.56 1.56 0 011.51 0l29.13 16.76c.09.04.17.13.22.22.04.07.05.17.05.27z'
      fill='url(#banner_svg__SVGID_171_)'
    />
    <path
      d='M103.65 285.13c0 .1-.03.19-.07.28-.05.09-.14.16-.23.22l-9.56 5.53-10.41 6-8.6 4.97c-.47.27-1.04.27-1.5 0l-9.17-5.27-10.37-5.97-9.56-5.49c-.19-.11-.3-.3-.3-.51v-.05c.01-.2.12-.37.29-.47l28.57-16.49a1.56 1.56 0 011.51 0l29.13 16.76c.09.04.17.13.22.22.04.07.05.17.05.27z'
      opacity={0.5}
      fill='#fff'
    />
    <linearGradient
      id='banner_svg__SVGID_172_'
      gradientUnits='userSpaceOnUse'
      x1={-100.852}
      y1={1.451}
      x2={99.843}
      y2={-6.568}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M102.96 284.94c0 .09-.02.19-.06.27-.05.09-.13.16-.22.22l-9.34 5.4-10.17 5.88-8.41 4.85c-.46.26-1.02.26-1.48 0l-9-5.16-10.17-5.84-9.33-5.4c-.19-.1-.3-.3-.3-.5v-.04c.01-.19.12-.37.29-.46l27.9-16.11c.45-.27 1.02-.27 1.47 0l28.48 16.37c.09.05.16.13.21.22.07.07.11.18.13.3z'
      fill='url(#banner_svg__SVGID_172_)'
    />
    <linearGradient
      id='banner_svg__SVGID_173_'
      gradientUnits='userSpaceOnUse'
      x1={86.106}
      y1={-14.014}
      x2={34.697}
      y2={21.465}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M96.47 284.91c0 .07-.03.14-.06.21-.04.07-.09.13-.16.16l-.1.06-.11.06-7.06 4.07-7.91 4.56-6.53 3.83c-.35.2-.79.2-1.14 0l-6.97-4.01-7.9-4.5-7.01-4.1-.16-.09-.09-.05a.458.458 0 01-.23-.4c.01-.14.09-.28.22-.35l21.71-12.61c.36-.2.79-.2 1.15 0l22.13 12.73c.06.04.13.1.16.17.04.09.06.18.06.26z'
      fill='url(#banner_svg__SVGID_173_)'
    />
    <linearGradient
      id='banner_svg__SVGID_174_'
      gradientUnits='userSpaceOnUse'
      x1={-8.084}
      y1={12.537}
      x2={113.535}
      y2={-13.465}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' />
      <stop offset={1} stopColor='#d9eaff' />
    </linearGradient>
    <path
      d='M96.04 285.42l-7.06 4.07-7.91 4.56-6.53 3.81c-.35.2-.79.2-1.14 0l-6.97-4.01-7.9-4.5-7.01-4.1 21.5-12.42c.36-.21.79-.21 1.15 0l21.87 12.59z'
      fill='url(#banner_svg__SVGID_174_)'
    />
    <path
      d='M89.12 284.89c.28.16.37.52.21.8-.05.08-.13.15-.21.21l-4.27 2.47-5.77 3.32-4.75 2.75c-.26.13-.58.13-.84 0l-5.08-2.88-5.75-3.31-4.24-2.44a.586.586 0 01-.21-.8c.05-.08.13-.15.21-.21l14.79-8.54c.26-.15.59-.15.85 0l15.06 8.63z'
      opacity={0.7}
      fill='none'
      stroke='#fff'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_175_'
      gradientUnits='userSpaceOnUse'
      x1={78.121}
      y1={14.442}
      x2={82.973}
      y2={14.442}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_175_)' d='M78.12 304.59l2.68 1.59 2.17-1.21-2.67-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_176_'
      gradientUnits='userSpaceOnUse'
      x1={83.261}
      y1={11.476}
      x2={88.112}
      y2={11.476}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_176_)' d='M83.26 301.62l2.67 1.59 2.18-1.2-2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_177_'
      gradientUnits='userSpaceOnUse'
      x1={88.391}
      y1={8.515}
      x2={93.251}
      y2={8.515}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_177_)' d='M88.39 298.66l2.68 1.59 2.18-1.2-2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_178_'
      gradientUnits='userSpaceOnUse'
      x1={93.53}
      y1={5.545}
      x2={98.381}
      y2={5.545}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_178_)' d='M93.53 295.69l2.68 1.59 2.17-1.2-2.67-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_179_'
      gradientUnits='userSpaceOnUse'
      x1={98.669}
      y1={2.584}
      x2={103.52}
      y2={2.584}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_179_)' d='M98.67 292.73l2.67 1.59 2.18-1.2-2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_180_'
      gradientUnits='userSpaceOnUse'
      x1={62.139}
      y1={14.473}
      x2={66.999}
      y2={14.473}
      gradientTransform='matrix(-1 0 0 1 132.12 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_180_)' d='M69.98 304.62l-2.68 1.59-2.17-1.2 2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_181_'
      gradientUnits='userSpaceOnUse'
      x1={67.274}
      y1={11.503}
      x2={72.125}
      y2={11.503}
      gradientTransform='matrix(-1 0 0 1 132.12 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_181_)' d='M64.85 301.65l-2.69 1.59-2.17-1.2 2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_182_'
      gradientUnits='userSpaceOnUse'
      x1={72.413}
      y1={8.542}
      x2={77.264}
      y2={8.542}
      gradientTransform='matrix(-1 0 0 1 132.12 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_182_)' d='M59.71 298.69l-2.68 1.58-2.17-1.19 2.67-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_183_'
      gradientUnits='userSpaceOnUse'
      x1={77.547}
      y1={5.572}
      x2={82.407}
      y2={5.572}
      gradientTransform='matrix(-1 0 0 1 132.12 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_183_)' d='M54.57 295.72l-2.68 1.58-2.17-1.19 2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_184_'
      gradientUnits='userSpaceOnUse'
      x1={82.682}
      y1={2.611}
      x2={87.533}
      y2={2.611}
      gradientTransform='matrix(-1 0 0 1 132.12 290.315)'
    >
      <stop offset={0} stopColor='#fbd242' />
      <stop offset={1} stopColor='#f56329' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_184_)' d='M49.44 292.76l-2.68 1.58-2.18-1.19 2.68-1.64z' />
    <linearGradient
      id='banner_svg__SVGID_185_'
      gradientUnits='userSpaceOnUse'
      x1={73.756}
      y1={143.823}
      x2={73.756}
      y2={-55.855}
      gradientTransform='translate(0 290.315)'
    >
      <stop offset={0} stopColor='#007aff' />
      <stop offset={0.5} stopColor='#009cff' stopOpacity={0.5} />
      <stop offset={1} stopColor='#d9eaff' stopOpacity={0} />
    </linearGradient>
    <path
      d='M96.25 236.59l-22.13-12.73c-.36-.2-.79-.2-1.15 0l-21.7 12.54c-.11.11-.19.24-.23.39v47.89c0 .16.08.32.23.4l.09.05.16.09 7.01 4.03 7.9 4.5 6.97 4.11c.35.2.79.2 1.14 0l6.53-3.78 7.91-4.56 7.06-4.07.11-.06.1-.06c.07-.04.13-.09.16-.16.04-.06.06-.14.06-.21V237c-.06-.14-.13-.28-.22-.41z'
      fill='url(#banner_svg__SVGID_185_)'
    />
  </svg>
)

export default SvgBanner
