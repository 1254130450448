import React from 'react'

const SvgTable = props => (
  <svg id='table_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 500 140' xmlSpace='preserve' {...props}>
    <style>
      {
        '.table_svg__st1{fill:none;stroke:#9aa9b2;stroke-miterlimit:10}.table_svg__st2{fill:#182940}.table_svg__st3{font-family:&apos;SourceHanSansCN-Normal-GBpc-EUC-H&apos;}.table_svg__st4{font-size:12px}.table_svg__st5{font-family:&apos;OpenSans&apos;}'
      }
    </style>
    <path fill='#d3d9dd' stroke='#9aa9b2' strokeMiterlimit={10} d='M.54 5.72h498.91v26H.54z' />
    <path className='table_svg__st1' d='M.54 31.72h498.91v52H.54z' />
    <path className='table_svg__st1' d='M85.35 57.72h414.09v26H85.35zM85.35 83.72h414.09v26H85.35z' />
    <path className='table_svg__st1' d='M.54 83.72h498.91v52H.54zM85.54 32.22v103M167.54 6.22v130M325.54 6.22v130' />
    <text transform='translate(11.037 22.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u6D4B\u8BD5\u573A\u666F'}
    </text>
    <text transform='translate(178.037 23.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u4F20\u8F93\u5B8C\u6210\u65F6\u95F4\uFF08\u79D2\uFF09'}
    </text>
    <text transform='translate(337.037 24.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u4F20\u8F93\u5E73\u5747\u901F\u5EA6\uFF08KB/\u79D2\uFF09'}
    </text>
    <text transform='translate(11.037 62.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'FTP\u4E0A\u4F20'}
    </text>
    <text transform='translate(95.037 48.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u52A0\u901F\u524D'}
    </text>
    <text transform='translate(95.037 74.556)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u52A0\u901F\u540E'}
    </text>
    <text transform='translate(95.037 100.889)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u52A0\u901F\u524D'}
    </text>
    <text transform='translate(95.037 127.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'\u52A0\u901F\u540E'}
    </text>
    <text transform='translate(178.037 49.222)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'27594'}
    </text>
    <text transform='translate(178.037 75.556)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'191'}
    </text>
    <text transform='translate(178.037 101.889)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'23301'}
    </text>
    <text transform='translate(178.037 128.222)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'184'}
    </text>
    <text transform='translate(337.037 50.222)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'38'}
    </text>
    <text transform='translate(337.037 76.556)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'5500'}
    </text>
    <text transform='translate(337.037 102.889)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'45'}
    </text>
    <text transform='translate(337.037 129.222)' className='table_svg__st2 table_svg__st5 table_svg__st4'>
      {'5700'}
    </text>
    <text transform='translate(11.037 108.222)' className='table_svg__st2 table_svg__st3 table_svg__st4'>
      {'FTP\u4E0B\u8F7D'}
    </text>
  </svg>
)

export default SvgTable
