import React from 'react'

const SvgTheoryEn = props => (
  <svg id='theory_en_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 977.26 288.22' xmlSpace='preserve' {...props}>
    <style>
      {
        '.theory_en_svg__st0{fill:#fd5c1f}.theory_en_svg__st1{fill:#6f7c8d}.theory_en_svg__st2{font-family:&apos;OpenSans&apos;}.theory_en_svg__st3{font-size:13px}.theory_en_svg__st4{fill:#252a3a}.theory_en_svg__st5{fill:none}.theory_en_svg__st6{font-size:16px}'
      }
    </style>
    <path
      className='theory_en_svg__st0'
      d='M508.29 62.61c-2.43-3.7-6.23-6.13-10.49-6.74-2.34-5.92-7.89-9.71-14.29-9.71-6.41 0-11.96 3.79-14.29 9.71-7.65 1.05-13.3 7.75-12.97 15.68.33 8.05 7.55 14.61 15.6 14.61h23.28c6.8 0 12.94-4.36 14.91-10.6 1.41-4.4.77-9.13-1.75-12.95zm-27.01 15.45c0 .46-.37.84-.84.84h-.45c-.46 0-.84-.37-.84-.84V64.11l-2.85 2.85c-.33.33-.86.33-1.18 0l-.32-.32a.827.827 0 010-1.18l4.41-4.41c.09-.27.31-.46.59-.53.26-.16.58-.16.85 0 .3.07.52.3.6.6.15.24.16.54.04.79v16.15h-.01zm10.48-4.14l-4.41 4.41c-.09.27-.31.46-.59.53-.26.16-.58.16-.85 0-.3-.07-.52-.3-.6-.6a.823.823 0 01-.04-.79V61.31c0-.46.37-.84.84-.84h.45c.46 0 .84.37.84.84v13.96l2.85-2.86c.33-.33.86-.33 1.18 0l.32.32c.33.33.33.86.01 1.19z'
    />
    <text transform='translate(238.608 51.079)' className='theory_en_svg__st1 theory_en_svg__st2 theory_en_svg__st3'>
      {'TCP'}
    </text>
    <text transform='translate(326.714 225.322)' className='theory_en_svg__st1 theory_en_svg__st2 theory_en_svg__st3'>
      {'Raysync Protocol'}
    </text>
    <path
      className='theory_en_svg__st4'
      d='M47.01 59.17v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.36-6.98-.99zM44.29 49.39c.03 1.51 4.16 3.2 9.7 3.2 5.55 0 9.7-1.7 9.7-3.21s-4.15-3.21-9.7-3.21c-5.54 0-9.69 1.69-9.7 3.22 0-.01 0-.01 0 0zM23.69 71.46v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.86-.89-11.02-2.56z'
    />
    <path
      className='theory_en_svg__st4'
      d='M23.69 64.75v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.86-.89-11.02-2.56zM47.01 65.43v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21V64.1c-1.97 1.51-5.92 2.31-9.7 2.31-2.6 0-5.03-.35-6.98-.98z'
    />
    <path
      className='theory_en_svg__st4'
      d='M23.69 58.03v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.86-.89-11.02-2.56zM53.99 53.87c-3.78 0-7.72-.8-9.7-2.31v1.17c1.66.77 2.72 1.8 2.72 3.07v1.99c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31zM47.01 71.7v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.36-6.98-.99zM23.69 78.18v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.86-.89-11.02-2.56z'
    />
    <path
      className='theory_en_svg__st4'
      d='M34.71 52.32c-6.3 0-11.02 1.84-11.02 3.49s4.71 3.49 11.02 3.49c6.3 0 11.01-1.84 11.01-3.49s-4.71-3.49-11.01-3.49z'
    />
    <path
      className='theory_en_svg__st0'
      d='M310.84 210.6c-2.33 0-4.65.45-6.69.95l1.51 1.09c-2.04.38-4.13.27-5.27.02v4.46c1.55.41 6.66.11 8.91-.81l-.7-.85c.83-.08 1.38-.1 2.25-.1 2.39 0 4.65.33 6.69.93v-4.73c-1.94-.49-4.38-.96-6.7-.96z'
    />
    <path
      className='theory_en_svg__st4'
      d='M317.94 227.89c-1.56.16-2.66.41-3.76.74v1.31c-1.72.39-4.52.77-6.77.63-2.32-.15-5.32-.99-6.62-1.48v-4.71c1.83.97 5.3 1.63 8.16 1.63 1.59 0 3.55-.21 4.7-.45l-.23-1.18c1.46-.5 2.38-.93 4.51-1.14l.01 4.65zM315.01 217.18c-1.63 0-4 .37-4.67.56l.78 1.25c-1.75.44-3.49.43-4.78.43-3.01 0-5.56-.64-8.21-1.64v4.72c2.04.69 4.66 1.24 7.14 1.24s5.21-.34 7.53-1.14l-.22-.57c.81-.1 1.5-.15 2.39-.15 1.67 0 3.07.06 4.82.47v-4.73c-.96-.22-3.15-.44-4.78-.44z'
    />
    <path className='theory_en_svg__st5' d='M98.17 66.16h340' />
    <path className='theory_en_svg__st4' d='M98.17 65.16h340v2h-340z' />
    <path className='theory_en_svg__st5' d='M532.67 66.16h350' />
    <path className='theory_en_svg__st4' d='M532.67 65.16h350v2h-350z' />
    <text transform='translate(341.666 17.111)' className='theory_en_svg__st4 theory_en_svg__st2 theory_en_svg__st6'>
      {'Before applying Raysync deployment'}
    </text>
    <path
      className='theory_en_svg__st4'
      d='M935.16 82.15c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.33-.78.5-1.24.47m-5.7 0c-.91-.05-1.61-.83-1.57-1.74.04-.91.79-1.63 1.7-1.61.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.33-.78.5-1.24.47zm-20.32-3.32h10v3.32h-10v-3.32zm-2.7-4h-3v9.32a2.037 2.037 0 002.15 2h35.62c.55.02 1.09-.18 1.49-.55.4-.38.64-.89.66-1.45v-9.32h-36.92zm28.8-21.32a1.83 1.83 0 01-1.95-1.6c.04-.93.77-1.67 1.7-1.72.94-.02 1.73.67 1.85 1.6-.01.9-.71 1.64-1.6 1.72zm-5.7 0a1.97 1.97 0 01-1.65-.8c-.32-.51-.32-1.16 0-1.67.41-.65 1.18-.97 1.93-.81s1.32.77 1.42 1.53c.03.46-.14.92-.46 1.25-.33.33-.78.51-1.24.5zm-20.4-3.35h10v3.35h-10v-3.35zm31.22-4h-33.87c-1.6-.04-2.93 1.2-3 2.8v8.55h39.99v-8.55a3.023 3.023 0 00-3.12-2.8zm-31.22 18.67h10v3.32h-10v-3.32zm20.25 0c.7-.01 1.34.38 1.65 1 .29.61.15 1.34-.35 1.8-.53.46-1.26.61-1.92.4-.65-.24-1.1-.83-1.15-1.52.06-.94.83-1.67 1.77-1.68zm5.7 0c.94-.02 1.73.67 1.85 1.6-.04.93-.77 1.67-1.7 1.72-.94.02-1.73-.67-1.85-1.6.01-.93.76-1.69 1.7-1.72zm-31.65 7.33h39.84V60.83h-39.99l.15 11.33z'
    />
    <path
      className='theory_en_svg__st0'
      d='M507.29 240.28c-2.43-3.7-6.23-6.13-10.49-6.74-2.34-5.92-7.89-9.71-14.29-9.71-6.41 0-11.96 3.79-14.29 9.71-7.65 1.05-13.3 7.75-12.97 15.68.33 8.05 7.55 14.61 15.6 14.61h23.28c6.8 0 12.94-4.36 14.91-10.6 1.41-4.4.77-9.12-1.75-12.95zm-27.01 15.46c0 .46-.37.84-.84.84h-.45c-.46 0-.84-.37-.84-.84v-13.96l-2.85 2.85c-.33.33-.86.33-1.18 0l-.32-.32a.827.827 0 010-1.18l4.41-4.41c.09-.27.31-.46.59-.53.26-.16.58-.16.85 0 .3.07.52.3.6.6.15.24.16.54.04.79v16.16h-.01zm10.48-4.15l-4.41 4.41c-.09.27-.31.46-.59.53-.26.16-.58.16-.85 0-.3-.07-.52-.3-.6-.6a.823.823 0 01-.04-.79v-16.16c0-.46.37-.84.84-.84h.45c.46 0 .84.37.84.84v13.96l2.85-2.86c.33-.33.86-.33 1.18 0l.32.32c.33.34.33.87.01 1.19z'
    />
    <text transform='translate(131.608 225.322)' className='theory_en_svg__st1 theory_en_svg__st2 theory_en_svg__st3'>
      {'TCP'}
    </text>
    <text transform='translate(873.387 285.079)' className='theory_en_svg__st4 theory_en_svg__st2 theory_en_svg__st3'>
      {'User APP Server'}
    </text>
    <text transform='translate(873.387 104.115)' className='theory_en_svg__st4 theory_en_svg__st2 theory_en_svg__st3'>
      {'User APP Server'}
    </text>
    <text transform='translate(11.765 283.079)' className='theory_en_svg__st4 theory_en_svg__st2 theory_en_svg__st3'>
      {'User APP'}
    </text>
    <text transform='translate(11.765 104.115)' className='theory_en_svg__st4 theory_en_svg__st2 theory_en_svg__st3'>
      {'User APP'}
    </text>
    <g>
      <path
        className='theory_en_svg__st4'
        d='M47.01 236.84v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.35-6.98-.99zM44.29 227.06c.03 1.51 4.16 3.2 9.7 3.2 5.55 0 9.7-1.7 9.7-3.21 0-1.52-4.15-3.21-9.7-3.21-5.54 0-9.69 1.69-9.7 3.21v.01zM23.69 249.14v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.86-.89-11.02-2.56z'
      />
      <path
        className='theory_en_svg__st4'
        d='M23.69 242.42v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.86-.88-11.02-2.56zM47.01 243.11v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31-2.6 0-5.03-.35-6.98-.98z'
      />
      <path
        className='theory_en_svg__st4'
        d='M23.69 235.71v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.86-.89-11.02-2.56zM53.99 231.55c-3.78 0-7.72-.8-9.7-2.31v1.17c1.66.77 2.72 1.8 2.72 3.07v1.99c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31zM47.01 249.38v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.03-.36-6.98-.99zM23.69 255.85v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.86-.88-11.02-2.56z'
      />
      <path
        className='theory_en_svg__st4'
        d='M34.71 230c-6.3 0-11.02 1.84-11.02 3.49 0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49S41.01 230 34.71 230z'
      />
    </g>
    <text transform='translate(189.591 285.079)' className='theory_en_svg__st0 theory_en_svg__st2 theory_en_svg__st3'>
      {'Raysync Proxy Client'}
    </text>
    <g>
      <path
        className='theory_en_svg__st0'
        d='M259.99 236.84v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.04-.35-6.98-.99zM257.27 227.06c.03 1.51 4.16 3.2 9.7 3.2 5.55 0 9.7-1.7 9.7-3.21 0-1.52-4.15-3.21-9.7-3.21-5.54 0-9.69 1.69-9.7 3.21v.01zM236.67 249.14v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.87-.89-11.02-2.56z'
      />
      <path
        className='theory_en_svg__st0'
        d='M236.67 242.42v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.87-.88-11.02-2.56zM259.99 243.11v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.92 2.31-9.7 2.31-2.6 0-5.04-.35-6.98-.98z'
      />
      <path
        className='theory_en_svg__st0'
        d='M236.67 235.71v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34-.01-8.87-.89-11.02-2.56zM266.96 231.55c-3.78 0-7.72-.8-9.7-2.31v1.17c1.66.77 2.72 1.8 2.72 3.07v1.99c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.08c-1.97 1.51-5.91 2.31-9.7 2.31zM259.99 249.38v4.9c1.86.67 4.36 1.06 6.98 1.06 5.55 0 9.7-1.7 9.7-3.21v-4.07c-1.97 1.51-5.92 2.31-9.7 2.31-2.6-.01-5.04-.36-6.98-.99zM236.67 255.85v4.49c0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49v-4.49c-2.16 1.67-6.68 2.56-11.01 2.56-4.34 0-8.87-.88-11.02-2.56z'
      />
      <path
        className='theory_en_svg__st0'
        d='M247.68 230c-6.3 0-11.02 1.84-11.02 3.49 0 1.65 4.71 3.49 11.02 3.49 6.3 0 11.01-1.84 11.01-3.49.01-1.65-4.7-3.49-11.01-3.49z'
      />
    </g>
    <g>
      <path className='theory_en_svg__st5' d='M79.67 243.83h140' />
      <path className='theory_en_svg__st4' d='M79.67 242.83h140v2h-140z' />
    </g>
    <g>
      <path className='theory_en_svg__st5' d='M297.67 243.83h140' />
      <path className='theory_en_svg__st4' d='M297.67 242.83h140v2h-140z' />
    </g>
    <g>
      <path className='theory_en_svg__st5' d='M530.67 243.83h140' />
      <path className='theory_en_svg__st4' d='M530.67 242.83h140v2h-140z' />
    </g>
    <g>
      <path className='theory_en_svg__st5' d='M742.67 243.83h140' />
      <path className='theory_en_svg__st4' d='M742.67 242.83h140v2h-140z' />
    </g>
    <g>
      <text transform='translate(340.666 178.111)' className='theory_en_svg__st4 theory_en_svg__st2 theory_en_svg__st6'>
        {'After applying Raysync deployment'}
      </text>
    </g>
    <g>
      <path
        className='theory_en_svg__st4'
        d='M935.16 259.83c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.32-.78.49-1.24.47m-5.7 0c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.32-.78.49-1.24.47zm-20.32-3.32h10v3.32h-10v-3.32zm-2.7-4h-3v9.32a2.037 2.037 0 002.15 2h35.62c.55.02 1.09-.18 1.49-.55.4-.38.64-.89.66-1.45v-9.32h-36.92zm28.8-21.33a1.83 1.83 0 01-1.95-1.6c.04-.93.77-1.67 1.7-1.72.94-.02 1.73.67 1.85 1.6-.01.9-.71 1.65-1.6 1.72zm-5.7 0a1.97 1.97 0 01-1.65-.8c-.32-.51-.32-1.16 0-1.67.41-.65 1.18-.97 1.93-.81s1.32.77 1.42 1.53c.03.46-.14.92-.46 1.25-.33.34-.78.52-1.24.5zm-20.4-3.34h10v3.35h-10v-3.35zm31.22-4h-33.87c-1.6-.04-2.93 1.2-3 2.8v8.55h39.99v-8.55a3.016 3.016 0 00-3.12-2.8zm-31.22 18.67h10v3.32h-10v-3.32zm20.25 0c.7-.01 1.34.38 1.65 1 .29.61.15 1.34-.35 1.8-.53.46-1.26.61-1.92.4-.65-.24-1.1-.83-1.15-1.52.06-.94.83-1.67 1.77-1.68zm5.7 0c.94-.02 1.73.67 1.85 1.6-.04.93-.77 1.67-1.7 1.72-.94.02-1.73-.67-1.85-1.6.01-.93.76-1.69 1.7-1.72zm-31.65 7.32h39.84v-11.32h-39.99l.15 11.32z'
      />
    </g>
    <text transform='translate(639.48 283.079)' className='theory_en_svg__st0 theory_en_svg__st2 theory_en_svg__st3'>
      {'Raysync Proxy Serve'}
    </text>
    <g>
      <path
        className='theory_en_svg__st0'
        d='M715.83 259.83c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.32-.78.49-1.24.47m-5.7 0c-.91-.05-1.61-.83-1.57-1.74s.79-1.63 1.7-1.61c.91.02 1.64.76 1.64 1.67-.01.46-.2.9-.53 1.21-.33.32-.78.49-1.24.47zm-20.32-3.32h10v3.32h-10v-3.32zm-2.7-4h-3v9.32a2.037 2.037 0 002.15 2h35.62c.55.02 1.09-.18 1.49-.55.4-.38.64-.89.66-1.45v-9.32h-36.92zm28.8-21.33a1.83 1.83 0 01-1.95-1.6c.04-.93.77-1.67 1.7-1.72.94-.02 1.73.67 1.85 1.6-.01.9-.7 1.65-1.6 1.72zm-5.7 0a1.97 1.97 0 01-1.65-.8c-.32-.51-.32-1.16 0-1.67.41-.65 1.18-.97 1.93-.81s1.32.77 1.42 1.53c.03.46-.14.92-.46 1.25-.33.34-.78.52-1.24.5zm-20.4-3.34h10v3.35h-10v-3.35zm31.22-4h-33.87c-1.6-.04-2.93 1.2-3 2.8v8.55h39.99v-8.55a3.003 3.003 0 00-3.12-2.8zm-31.22 18.67h10v3.32h-10v-3.32zm20.25 0c.7-.01 1.34.38 1.65 1 .29.61.15 1.34-.35 1.8-.53.46-1.26.61-1.92.4-.65-.24-1.1-.83-1.15-1.52.06-.94.83-1.67 1.77-1.68zm5.7 0c.94-.02 1.73.67 1.85 1.6-.04.93-.77 1.67-1.7 1.72-.94.02-1.73-.67-1.85-1.6.01-.93.76-1.69 1.7-1.72zm-31.65 7.32h39.84v-11.32h-39.99l.15 11.32z'
      />
    </g>
  </svg>
)

export default SvgTheoryEn
