import styled, { css } from 'styled-components'
import { Wrapper, Flex } from '@raysync/common/components'
import { transition, Media } from '@raysync/common/theme'

export const DemandSection = styled(Wrapper)`
  ${({ theme: { color, typography, spacing } }) => css`
    padding: 67px 0;
    p {
      font-size: ${typography.textSmall};
      line-height: 24px;
      color: ${color.text};
      :last-child {
        margin-top: ${spacing.base};
      }
    }
    ${Media.phone`
      padding-top: .8rem;
      padding-bottom: .7rem;
      p{
        font-size: .24rem;
        line-height: .4rem;
      }
    `}
  `}
`

export const FeatureSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 68px 0 70px 0;
    background-color: ${color.panel};
    svg {
      display: block;
      width: 48px;
      height: 48px;
    }
    ${Media.phone`
      padding-top: .72rem;
      padding-bottom: .82rem;
      svg {
        width: .62rem;
        height: .62rem;
      }
    `}
  `}
`
export const SSHFeatureSection = styled(FeatureSection)`
  background: white;
`

export const TheorySection = styled(Wrapper)`
  ${({ theme: { color, typography, Hack } }) => css`
    padding: 79px 0 106px 0;
    > p {
      color: ${color.text};
      font-size: ${typography.textSmall};
      line-height: 1;
      margin: 28px 0 42px 0;
    }
    > svg {
      display: block;
      width: 970px;
      min-height: 287px;
      margin: 55px auto 0;
    }
    ${Hack.desktopEn`
      font-size: 18px;
    `}

    ${Media.phone`
      padding-bottom: 1.1rem;
      padding-top: .8rem;
      svg{
        width: 100%;
        min-height: initial;
      }
      >p{
        font-size: .24rem;
        line-height: 1.4;
        margin: .24rem 0 .33rem 0;
      }
    `}
  `}
`

export const ProcessSection = styled.div`
  ${({ theme: { color } }) => css`
    background-color: ${color.panel};
    padding: 67px 0 76px 0;
    ${Media.phone`
      padding-bottom: 1rem;
      padding-top: .75rem;
    `}
  `}
`

export const ProcessContainer = styled(Flex)`
  justify-content: space-between;
  > svg {
    width: 645px;
    min-height: 400px;
    display: block;
  }
  ${Media.phone`
    flex-wrap: wrap;
    >svg {
      width: 100%;
      min-height: initial;
      margin-bottom: .62rem;
    }
    img {
      width:100%;
      margin-bottom: .3rem;
    }
  `}
`
export const ProcessItem = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    width: 500px;
    min-height: 120px;
    border: 1px solid ${color.line};
    background-color: white;
    padding: ${spacing.base} ${spacing.small} ${spacing.base} 15px;
    :not(:last-child) {
      margin-bottom: ${spacing.large};
    }
    display: flex;
    align-items: center;
    h4 {
      line-height: 1;
      font-size: 20px;
      color: ${color.primary};
      padding-bottom: 5px;
    }
    p {
      color: ${color.text};
      font-size: ${typography.textSmall};
      line-height: 24px;
    }
    svg {
      display: block;
      margin-right: ${spacing.base};
      width: 60px;
      height: 60px;
    }
    ${Media.phone`
      width: 100%;
      :not(:last-child) {
      margin-bottom: .3rem;
      }
      p{
        font-size: .24rem;
        line-height: .4rem;
      }
      svg {
        margin-right: .27rem;
        width: .83rem;
        height: .83rem;
      }
    `}
  `}
`
export const AdvantageSection = styled(Wrapper)`
  padding: 72px 0 83px 0;
  svg {
    width: 62px;
    height: 62px;
  }
  ${Media.phone`
    padding-bottom: 1rem;
    padding-top: .72rem;
    svg{
      width: .6rem;
      height: .6rem;
    }
  `}
`
export const ExampleSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 68px 0;
    background-color: ${color.panel};
    ${Media.phone`
      padding-bottom: .72rem;
      padding-top: .75rem;
    `}
  `}
`
export const ExampleContainer = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 30px 62px 50px;
    box-shadow: 0px 0px 12px 0px rgba(226, 231, 236, 1);
    margin-bottom: ${spacing.base};
    h4 {
      line-height: 24px;
    }
    p {
      width: 500px;
      font-size: ${typography.textThin};
      color: ${color.text};
      line-height: 20px;
    }
    ${Media.phone`
      padding: .3rem .25rem .4rem .3rem;
      flex-wrap:wrap;
      p {
        width: 100%;
      }
    `}
  `}
`
export const ExampleLeft = styled.div`
  width: 500px;
  margin-right: 90px;
  > img {
    width: 500px;
    margin-bottom: 16px;
  }
  p {
    :nth-of-type(2) {
      margin: 12px 0 6px 0;
    }
  }

  ${p => p.theme.Hack.desktopEn`
    line-height: 24px;
  `}

  ${Media.phone`
    width: 100%;
    margin-right: 0;
    >img {
      width: 100%;
    }
  `}
`
export const ExampleRight = styled.div`
  > img {
    height: 30px;
    margin-bottom: 26px;
  }
  p:nth-of-type(1) {
    margin: 12px 0 15px 0;
  }

  ${p => p.theme.Hack.desktopEn`
    p {
      line-height: 24px;
    }
    h4 {
      font-size: 26px;
      line-height: 1.2;
    }
  `}

  ${Media.phone`
    h4{
      line-height: .4rem;
      font-size: .3rem;
    }
    >img{
      display:block;
      height: .4rem;
      margin: .2rem 0;
    }
  `}
`
export const FeatureContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${spacing.base};
  `}
`
export const FeatureItem = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    padding: 40px 34px 28px;
    width: 380px;
    min-height: 240px;
    text-align: center;
    box-shadow: 0px 0px 12px 0px rgba(226, 231, 236, 1);
    div {
      width: 48px;
      height: 48px;
      margin: 0 auto 18px;
      svg {
        width: 48px;
      }
    }
    h4 {
      font-size: 20px;
      line-height: 36px;
      color: ${color.primary};
    }
    p {
      color: ${color.text};
      font-size: ${typography.textThin};
      line-height: 24px;
    }

    ${Hack.desktopEn`
      h4 {
        min-height:72px;
        font-size: ${typography.h3};
        line-height: 1.2;
        margin-bottom: 10px;
      }
    `}

    ${Media.phone`
      min-height: initial;
      margin-bottom: .5rem;
      padding: .6rem .5rem .8rem;
      div {
        margin-bottom: .2rem;
        svg {
          width: .87rem;
          height: .87rem;
        }
      }
      h4 {
        font-size: .36rem;
        line-height: .5rem;
        min-height: initial;
        margin-bottom: .2rem;
      }
    `}
  `}
`

export const ScenesSection = styled(Wrapper)`
  padding: 10px 0 90px 0;
  ${Media.phone`
    padding-top: .1rem;
    padding-bottom: .85rem;
  `}
`
export const ScenesContainer = styled.div`
  padding: 0 100px;
  width: 100%;
  height: 296px;
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0.5rem 0;
    height: initial;
  `}
`
export const ScenesBox = styled.div`
  ${({ theme: { color, typography } }) => css`
    width: 460px;
    img {
      height: 224px;
    }
    p {
      padding-left: 22px;
      color: ${color.primary};
      line-height: 70px;
      font-size: ${typography.h4};
      border: 1px solid #d4dde1;
      font-weight: 500;
    }
    transition: ${transition(['margin-top'])};
    :hover {
      margin-top: -10px;
    }
    ${Media.phone`
      width: 100%;
      margin-bottom: .4rem;
      img {
        width: 100%;
        height: 2.92rem;
      }
      p{
        padding-left: .3rem;
        line-height: 1rem;
        font-size: .3rem;
      }
    `}
  `}
`
