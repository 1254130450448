import React from 'react'

const SvgProcess1 = props => (
  <svg viewBox='0 0 60 60' {...props}>
    <circle cx={30} cy={30} r={30} fill='#fd5c1f' />
    <path
      d='M27.95 26.62h-5.73c-.23 0-.41.18-.41.41 0 .23.18.41.41.41h5.73c.23 0 .41-.18.41-.41 0-.22-.18-.41-.41-.41zm0 4.1h-5.73c-.23 0-.41.18-.41.41 0 .23.18.41.41.41h5.73c.23 0 .41-.18.41-.41 0-.23-.18-.41-.41-.41zm6.14-7.27c-2.26 0-4.09 1.84-4.09 4.09 0 .92.31 1.75.82 2.43v4.52c0 .15.08.28.2.35.13.07.28.07.41 0l2.66-1.52 2.66 1.52c.06.04.13.05.2.05s.14-.02.21-.06c.13-.07.2-.21.2-.35V30v-.02c.51-.68.82-1.52.82-2.43 0-2.26-1.83-4.1-4.09-4.1zm2.46 10.35l-2.25-1.29a.426.426 0 00-.41 0l-2.25 1.29v-3c.69.52 1.53.84 2.45.84.92 0 1.77-.32 2.45-.84v3zm-2.46-2.98c-1.8 0-3.27-1.47-3.27-3.27s1.47-3.27 3.27-3.27 3.27 1.47 3.27 3.27-1.46 3.27-3.27 3.27zm10.52-11.88c-1.73-.59-5.14-1.71-6.86-2.57-3.44-1.71-4.74-2.88-6.03-3.85-.45-.35-1.08-.51-1.7-.51h-.04c-.63 0-1.25.17-1.7.51-1.28.98-2.58 2.14-6.03 3.85-1.72.86-5.13 1.98-6.86 2.57-1.12.38-1.84 1.39-1.74 2.57.3 3.65 1.2 10.95 3.44 15.42 2.58 5.14 9.06 9.16 11.62 10.71.4.24.85.36 1.29.36s.89-.12 1.29-.37c2.56-1.54 9.04-5.57 11.62-10.71 2.24-4.47 3.14-11.77 3.44-15.42.1-1.17-.62-2.18-1.74-2.56zm-3.16 17.25c-1.63 3.25-5.33 6.62-11 10.04-.14.09-.29.13-.45.13s-.3-.04-.45-.13c-5.67-3.42-9.37-6.79-11-10.04-2.28-4.54-3.08-12.49-3.27-14.82-.05-.6.48-.83.64-.89l.62-.21c1.92-.65 4.81-1.63 6.44-2.44 3.13-1.56 4.6-2.71 5.79-3.63.17-.13.34-.26.5-.39.1-.08.33-.27.64-.28H30.05c-.06 0 .57.2.67.28.17.13.33.26.5.39 1.18.92 2.65 2.07 5.79 3.63 1.63.81 4.53 1.79 6.44 2.44l.62.21c.16.06.69.28.64.89-.18 2.33-.98 10.28-3.26 14.82zM39 21.72H21c-.9 0-1.64.74-1.64 1.64v11.45c0 .9.74 1.64 1.64 1.64h18c.9 0 1.64-.74 1.64-1.64V23.35c0-.9-.74-1.63-1.64-1.63zm0 13.91H21c-.45 0-.82-.37-.82-.82V23.35c0-.45.37-.82.82-.82h18c.45 0 .82.37.82.82V34.8c0 .46-.37.83-.82.83zM34.09 25.5c-1.13 0-2.05.92-2.05 2.05s.92 2.05 2.05 2.05 2.05-.92 2.05-2.05-.92-2.05-2.05-2.05zm0 3.27a1.23 1.23 0 110-2.46 1.23 1.23 0 110 2.46z'
      fill='#fff'
    />
  </svg>
)

export default SvgProcess1
