import React from 'react'

const SvgProcess2 = props => (
  <svg id='process2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 60 60' xmlSpace='preserve' {...props}>
    <style>{'.process2_svg__st1{fill:#fff}'}</style>
    <circle cx={30} cy={30} r={30} fill='#fd5c1f' />
    <path className='process2_svg__st1' d='M25 23h2v2h-2zM38 27H20l4 4v-2h16v-6h-2zM24 41h12v2H24z' />
    <path className='process2_svg__st1' d='M14 12v36h32V12H14zm2 34V14h28v32H16z' />
    <path className='process2_svg__st1' d='M22 21h18l-4-4v2H20v6h2z' />
    <path className='process2_svg__st1' d='M29 23h2v2h-2zM33 23h2v2h-2z' />
  </svg>
)

export default SvgProcess2
