import React from 'react'

const SvgFeature2 = props => (
  <svg id='feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature2_svg__st0{fill:#fd5c1f}'}</style>
    <g id='feature2_svg__\u96C6\u6210\u7BA1\u7406-48x48'>
      <g id='feature2_svg__Group_2_' transform='translate(1 1)'>
        <path id='feature2_svg__Fill-2_5_' className='feature2_svg__st0' d='M7 14l17-9 17 9-17 8.7-10.3-5.4z' />
        <path id='feature2_svg__Fill-3_3_' className='feature2_svg__st0' d='M39 24l-2-1-4 2 2 1z' />
        <path
          id='feature2_svg__Fill-4_4_'
          d='M24 33.7l-10.3-5.4L5.3 24l8.4-4.4L24 25l10.3-5.4 8.4 4.4L24 33.7zM42.7 35L24 44.7 5.3 35l8.4-4.4L24 36l10.3-5.4 8.4 4.4zM5.3 13L24 3.3 42.7 13 24 22.7l-10.3-5.4L5.3 13zm31.2 5.5L47 13 24 1 1 13l10.5 5.5L1 24l10.5 5.5L1 35l23 12 23-12-10.5-5.5L47 24l-10.5-5.5z'
          fill='#334966'
        />
        <path id='feature2_svg__Fill-5_2_' className='feature2_svg__st0' d='M35 37l4-2-2-1-4 2z' />
      </g>
    </g>
  </svg>
)

export default SvgFeature2
