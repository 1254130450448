import styled, { css } from 'styled-components'
import { Wrapper, Flex } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

export const ApplicationSection = styled(Wrapper)`
  padding: 70px 0 80px 0;
  ${Media.phone`
    padding-top: .7rem;
    padding-bottom: 1rem;
  `}
`
export const ApplicationContainer = styled(Flex)`
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
  `}
`
export const Application = styled.div`
  ${({ theme: { color, typography } }) => css`
    margin-top: 40px;
    width: 224px;
    height: 240px;
    border: 1px solid ${color.line};

    svg {
      display: block;
      width: 122px;
      height: 86px;
      margin: 40px auto 40px;
    }
    p {
      text-align: center;
      font-size: ${typography.text};
      color: ${color.primary};
      line-height: 1.3;
    }
    ${Media.phone`
      width: 3.2rem;
      height: 3.4rem;
      svg{
        margin: .58rem auto;
        width: 1.73rem;
        height: 1.22rem;
      }
      p{
        line-height:1.2;
      }
    `}
  `}
`
export const FeatureSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 68px 0 70px 0;
    background-color: ${color.panel};
    ${Media.phone`
      padding-top: .74rem;
      padding-bottom: .85rem;
    `}
  `}
`
export const ButtonContainer = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.base};
    justify-content: center;
    button:last-child {
      margin-left: ${spacing.base};
    }
  `}
`
export const ArchSection = styled(Wrapper)`
  padding: 82px 0 70px 0;
  svg,
  img {
    display: block;
    margin: 50px auto 0;
    width: 700px;
    height: 500px;
  }
  ${Media.phone`
    padding-top: .77rem;
    padding-bottom: 1.17rem;
    svg,
    img {
      height: 5rem;
      width: 100%;
    }
  `}
`
export const ExampleSection = styled.div`
  ${({ theme: { color } }) => css`
    background-color: ${color.panel};
    padding: 68px 0;
    ${Media.phone`
      padding-top: .72rem;
      padding-bottom: .72rem;
    `}
  `}
`
export const ExampleContainer = styled(Flex)`
  ${({ theme: { spacing, Hack } }) => css`
    background-color: white;
    justify-content: space-between;
    padding: 40px 38px 40px 132px;
    box-shadow: 0px 0px 12px 0px rgba(226, 231, 236, 1);
    margin-bottom: ${spacing.base};
    div:first-child {
      margin-right: 100px;
    }
    img {
      display: block;
      height: 320px;
    }
    ${Hack.desktopEn`
      padding-left:50px;
      div:first-child {
        margin-right: 40px;
      }
    `}

    ${Media.phone`
      padding: .33rem .32rem .4rem .36rem;
      flex-wrap: wrap;
      div:first-child {
      margin: 0 auto;
      }
      img {
        height: 3.2rem;
      }
    `}
  `}
`
export const DemandSection = styled(Wrapper)`
  ${({ theme: { color, typography, spacing } }) => css`
    padding: 67px 0;
    p {
      font-size: ${typography.textSmall};
      line-height: 24px;
      color: ${color.text};
      :last-child {
        margin-top: ${spacing.base};
      }
    }
  `}
`

export const FtpExampleSection = styled(ExampleSection)`
  ${({ theme: { color } }) => css`
    background-color: ${color.panel};
  `}
`

export const FtpExampleContainer = styled(ExampleContainer)`
  background-color: white;
  padding: 50px 30px;
  div:first-child {
    margin-right: 53px;
  }
  ${Media.phone`
    div:first-child {
     margin: 0 auto;
    }
  `}
`

export const ExampleContent = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    > img {
      height: 40px;
      margin-bottom: 23px;
    }
    h4 {
      line-height: 24px;
    }
    p {
      font-size: ${typography.textThin};
      color: ${color.text};
      line-height: 20px;
      margin-top: 21px;
      &:last-child {
        margin-top: 15px;
      }
    }

    ${Hack.desktopEn`
      h4 {
        font-size: ${typography.h3};
        line-height: 1.2;
      }
      p {
        line-height: 24px;
      }
    `}

    ${Media.phone`
      img{
        height: .6rem;
        margin: .3rem 0;
      }
      p{
        margin-top: .3rem;
      }
    `}
  `}
`
