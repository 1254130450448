import React from 'react'

const SvgAdvantage1 = props => (
  <svg id='advantage1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 64 64' xmlSpace='preserve' {...props}>
    <style>{'.advantage1_svg__st0{fill:#2b4b67}'}</style>
    <path
      className='advantage1_svg__st0'
      d='M19.13 52c0-.6-.4-1-1-1h-14c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h56c1.1 0 2 .9 2 2v19c0 .6.4 1 1 1s1-.4 1-1V8c0-2.2-1.8-4-4-4h-56c-2.2 0-4 1.8-4 4v41c0 2.2 1.8 4 4 4h14c.6 0 1-.4 1-1zM56.75 58h-49c-.6 0-1 .4-1 1s.4 1 1 1h49c.6 0 1-.4 1-1s-.4-1-1-1z'
    />
    <path
      d='M60.71 35.34c-1.82-2.78-4.67-4.6-7.86-5.05C51.1 25.84 46.93 23 42.13 23c-4.8 0-8.97 2.84-10.72 7.28-5.58.77-9.73 5.54-9.73 11.26 0 6.32 5.14 11.45 11.45 11.45h17.72c5.1 0 9.7-3.27 11.19-7.95 1.04-3.29.56-6.83-1.33-9.7zm-19.33 10.9c0 .53-.35.7-.88.7s-.88-.35-.88-.88v-9.8l-2.8 3.33c-.35.35-.88.52-1.22.17-.35-.35-.53-.88-.18-1.23l4.38-5.25c.35-.17.7-.35 1.05-.17.35.17.52.53.52.88v12.25zm8.44-4.8l-4.38 5.25c-.18.17-.52.35-.7.35h-.35c-.35-.17-.52-.52-.52-.88V33.92c0-.52.35-.88.88-.88s.88.35.88.88v9.8l2.8-3.33c.35-.35.88-.53 1.22-.17.35.35.53.87.17 1.22z'
      fill='#fd5c1f'
    />
    <path className='advantage1_svg__st0' d='M9.13 12h26v2h-26zM9.13 19h15v2h-15zM9.13 26h10v2h-10z' />
  </svg>
)

export default SvgAdvantage1
