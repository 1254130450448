import React from 'react'

const SvgFeature5 = props => (
  <svg id='feature5_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature5_svg__st0{fill:#334966}.feature5_svg__st2{fill:#fd5c1f}'}</style>
    <g id='feature5_svg__\u7075\u6D3B\u591A\u7AEF'>
      <path id='feature5_svg__Combined-Shape_22_' className='feature5_svg__st0' d='M49 10h-2V6H3v32h20v2H1V4h48z' />
      <path id='feature5_svg__Rectangle-17' className='feature5_svg__st0' d='M13 44h10v2H13z' />
      <path id='feature5_svg__Rectangle-73' className='feature5_svg__st0' d='M21 38h2v8h-2z' />
      <path id='feature5_svg__Rectangle-77' fill='none' stroke='#334966' strokeWidth={2} d='M28 15h20v30H28z' />
      <path id='feature5_svg__Rectangle_2_' className='feature5_svg__st0' d='M28 38h21v2H28z' />
      <path id='feature5_svg__Rectangle-Copy' className='feature5_svg__st2' d='M31 18h16v20H31z' />
      <path id='feature5_svg__Rectangle-Copy-2' className='feature5_svg__st2' d='M9 14h12v2H9z' />
      <path id='feature5_svg__Rectangle-Copy-3' className='feature5_svg__st2' d='M9 20h12v2H9z' />
      <path id='feature5_svg__Rectangle-Copy-4' className='feature5_svg__st2' d='M9 26h12v2H9z' />
    </g>
  </svg>
)

export default SvgFeature5
