import React from 'react'

const SvgTableEn = props => (
  <svg id='table_en_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 500 140' xmlSpace='preserve' {...props}>
    <style>
      {
        '.table_en_svg__st1{fill:none;stroke:#9aa9b2;stroke-miterlimit:10}.table_en_svg__st2{fill:#182940}.table_en_svg__st3{font-family:&apos;OpenSans&apos;}.table_en_svg__st4{font-size:12px}'
      }
    </style>
    <path fill='#d3d9dd' stroke='#9aa9b2' strokeMiterlimit={10} d='M.49 2.52h498.99v26H.49z' />
    <path className='table_en_svg__st1' d='M.49 28.52h498.99v52H.49z' />
    <path className='table_en_svg__st1' d='M91.31 54.52h408.17v26H91.31z' />
    <path className='table_en_svg__st1' d='M91.31 80.52h408.17v26H91.31z' />
    <path className='table_en_svg__st1' d='M.49 80.52h498.99v52H.49zM91.49 29.02v103M217.49 3.02v130M365.49 3.02v130' />
    <text transform='translate(5.994 19.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Test scenarios'}
    </text>
    <text transform='translate(227.995 20.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Completion duration(s)'}
    </text>
    <text transform='translate(376.995 21.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Average speed(KB/s)'}
    </text>
    <text transform='translate(5.994 59.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'FTP Upload'}
    </text>
    <text transform='translate(99.994 45.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Before acceleration'}
    </text>
    <text transform='translate(99.994 71.352)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'After acceleration'}
    </text>
    <text transform='translate(99.994 97.685)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'Before acceleration'}
    </text>
    <text transform='translate(99.994 124.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'After acceleration'}
    </text>
    <text transform='translate(227.995 46.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'27594'}
    </text>
    <text transform='translate(227.995 72.352)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'191'}
    </text>
    <text transform='translate(227.995 98.685)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'23301'}
    </text>
    <text transform='translate(227.995 125.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'184'}
    </text>
    <text transform='translate(376.995 47.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'38'}
    </text>
    <text transform='translate(376.995 73.352)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'5500'}
    </text>
    <text transform='translate(376.995 99.685)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'45'}
    </text>
    <text transform='translate(376.995 126.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'5700'}
    </text>
    <text transform='translate(5.994 105.019)' className='table_en_svg__st2 table_en_svg__st3 table_en_svg__st4'>
      {'FTP Dawnload'}
    </text>
  </svg>
)

export default SvgTableEn
