import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'

export const TableContainer = styled.div`
  ${({ theme: { color, typography } }) => css`
    display: flex;
    justify-content: center;
    table {
      width: 960px;
      border: 1px solid ${color.line};
      border-collapse: collapse;
      font-size: ${typography.textSmall};
      color: ${color.text};
      td {
        max-width: 360px;
        padding: 15px 25px 24px 20px;
        line-height: 24px;
        vertical-align: top;
      }
      th {
        padding-left: 20px;
        line-height: 40px;
        font-weight: normal;
        text-align: left;
      }
    }
    ${Media.phone`
      table {
        width: 100%;
        td {
          padding: .16rem .08rem .26rem .15rem;
          font-size: .24rem;
        }
        th {
          line-height: .5rem;
          padding-left: .13rem;
          font-size: .24rem;
        }
      }
    `}
  `}
`
