import React from 'react'

const SvgAdvantage2 = props => (
  <svg id='advantage2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 64 64' xmlSpace='preserve' {...props}>
    <style>
      {
        '.advantage2_svg__st0{stroke-linecap:round}.advantage2_svg__st0,.advantage2_svg__st2{fill:none;stroke:#2b4b67;stroke-width:2;stroke-miterlimit:10}'
      }
    </style>
    <path
      className='advantage2_svg__st0'
      d='M16.06 6.86C20.48 3.79 25.84 2 31.63 2c8.69 0 16.44 4.05 21.46 10.37M19.9 54.16C10.64 49.76 4.24 40.33 4.24 29.4c0-2.85.43-5.6 1.24-8.18M59.02 29.39c0 13.36-9.56 24.48-22.2 26.9'
    />
    <circle cx={31.63} cy={29.39} fill='#fd5c1f' r={8.63} />
    <circle className='advantage2_svg__st2' cx={31.63} cy={56.81} r={5.19} />
    <circle className='advantage2_svg__st2' cx={7.92} cy={16.48} r={5.19} />
    <circle className='advantage2_svg__st2' cx={56.08} cy={16.8} r={5.19} />
  </svg>
)

export default SvgAdvantage2
