import React from 'react'
import intl from 'react-intl-universal'
import { CommonBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { LOCALES, Navigation, LocaleService } from '@raysync/common/services'
import { Swiper, Button, Wrapper, H4, Link } from '@raysync/common/components'
import DocumentCard from '../../components/document-cards'

import Layout from '../../components/layout'
import Table from '../../components/table'
import { ButtonContainer } from './product-sdk.atoms'
import {
  DemandSection,
  FeatureSection,
  TheorySection,
  ProcessSection,
  ProcessContainer,
  ProcessItem,
  AdvantageSection,
  ExampleSection,
  ExampleContainer,
  ExampleLeft,
  ExampleRight,
} from './product-vpn.atoms'
import List from '../../components/list'

import Logo from '../../images/products/vpn/logo.png'
// import Example from '../../images/products/vpn/example_table.png'
import TableZh from '@raysync/common/components/svg/products/vpn/table'
import TableEn from '@raysync/common/components/svg/products/vpn/table-en'

import Banner from '@raysync/common/components/svg/products/vpn/banner'
import Feature1 from '@raysync/common/components/svg/products/vpn/feature1'
import Feature2 from '@raysync/common/components/svg/products/vpn/feature2'
import Feature3 from '@raysync/common/components/svg/products/vpn/feature3'
import Feature4 from '@raysync/common/components/svg/products/vpn/feature4'
import Feature5 from '@raysync/common/components/svg/products/vpn/feature5'
import Feature6 from '@raysync/common/components/svg/products/vpn/feature6'

import Process1 from '@raysync/common/components/svg/products/vpn/process1'
import Process2 from '@raysync/common/components/svg/products/vpn/process2'
import Process3 from '@raysync/common/components/svg/products/vpn/process3'

import Advantage1 from '@raysync/common/components/svg/products/vpn/advantage1'
import Advantage2 from '@raysync/common/components/svg/products/vpn/advantage2'

import Theory from '@raysync/common/components/svg/products/vpn/theory'
import TheoryEn from '@raysync/common/components/svg/products/vpn/theory-en'

import Process from '@raysync/common/components/svg/products/vpn/process'
import ProcessEn from '@raysync/common/components/svg/products/vpn/process-en'

const tablePic = {
  [LOCALES.ZH]: <TableZh />,
  [LOCALES.EN]: <TableEn />,
  [LOCALES.JA]: <TableEn />,
}[LocaleService.currentLocale]

class ProductSDK extends React.PureComponent {
  state = {
    processList: [
      { desc: intl.get('product3.process.desc1'), svg: <Process1 /> },
      { desc: intl.get('product3.process.desc2'), svg: <Process2 /> },
      { desc: intl.get('product3.process.desc3'), svg: <Process3 /> },
    ],
    advantageList: [
      {
        title: intl.get('product3.advantage1.title'),
        desc: intl.get('product3.advantage1.desc'),
        svg: <Advantage1 />,
      },
      {
        title: intl.get('product3.advantage2.title'),
        desc: intl.get('product3.advantage2.desc'),
        svg: <Advantage2 />,
      },
    ],

    featureList: [
      {
        title: intl.get('product3.feature.item1.title'),
        desc: intl.get('product3.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('product3.feature.item2.title'),
        desc: intl.get('product3.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('product3.feature.item3.title'),
        desc: intl.get('product3.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('product3.feature.item4.title'),
        desc: intl.get('product3.feature.item4.desc'),
        svg: <Feature4 />,
      },
      {
        title: intl.get('product3.feature.item5.title'),
        desc: intl.get('product3.feature.item5.desc'),
        svg: <Feature5 />,
      },
      {
        title: intl.get('product3.feature.item6.title'),
        desc: intl.get('product3.feature.item6.desc'),
        svg: <Feature6 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList, processList, advantageList } = this.state
    const processPic = {
      [LOCALES.ZH]: <Process />,
      [LOCALES.EN]: <ProcessEn />,
      [LOCALES.JA]: <ProcessEn />,
    }[intl.options.currentLocale]
    const theoryPic = {
      [LOCALES.ZH]: <Theory />,
      [LOCALES.EN]: <TheoryEn />,
      [LOCALES.JA]: <TheoryEn />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='product3' location={location}>
        <Swiper>
          <CommonBanner type='product3' pic={<Banner />} />
        </Swiper>
        <DemandSection>
          <CommonTitle bottom={35}>{intl.get('product3.needs.title')}</CommonTitle>
          <p>{intl.get('product3.needs.desc1')}</p>
          <p>{intl.get('product3.needs.desc2')}</p>
        </DemandSection>
        <FeatureSection>
          <Wrapper>
            <CommonTitle bottom={44}>{intl.get('product3.feature')}</CommonTitle>
            <List data={featureList} />
            <ButtonContainer>
              <Button variant='outlined' onClick={() => Navigation.toApply({ type: 'prompt', pageType: 'product3' })}>
                {intl.get('product3.feature.button1')}
              </Button>
              <Button onClick={() => Navigation.toApply({ type: 'apply', pageType: 'product3' })}>
                {intl.get('product3.feature.button2')}
              </Button>
            </ButtonContainer>
          </Wrapper>
        </FeatureSection>
        <TheorySection>
          <CommonTitle>{intl.get('product3.theory.title')}</CommonTitle>
          <p>{intl.get('product3.theory.desc1')}</p>
          <Table type='product3' />
          {theoryPic}
        </TheorySection>
        <ProcessSection>
          <Wrapper>
            <CommonTitle bottom={54}>{intl.get('product3.process.title')}</CommonTitle>
            <ProcessContainer>
              {processPic}
              <div>
                {processList.map((l, i) => (
                  <ProcessItem key={i}>
                    <div>{l.svg}</div>
                    <p>{l.desc}</p>
                  </ProcessItem>
                ))}
              </div>
            </ProcessContainer>
          </Wrapper>
        </ProcessSection>
        <AdvantageSection>
          <CommonTitle bottom={40}>{intl.get('product3.advantage.title')}</CommonTitle>
          <List data={advantageList} />
        </AdvantageSection>
        <ExampleSection>
          <Wrapper>
            <CommonTitle bottom={45}>{intl.get('product3.example')}</CommonTitle>
            <ExampleContainer>
              <ExampleLeft>
                <p>{intl.get('product3.example.desc4')}</p>
                <p>{intl.get('product3.example.desc5')}</p>
                {tablePic}
                <p>{intl.get('product3.example.desc6')}</p>
              </ExampleLeft>
              <ExampleRight>
                <img src={Logo} alt='Fox renderfarm Logo' />
                <H4>{intl.get('product3.example.desc1')}</H4>
                <p>{intl.get('product3.example.desc2')}</p>
                <p>{intl.get('product3.example.desc3')}</p>
              </ExampleRight>
            </ExampleContainer>
            <ButtonContainer>
              <Link to={'/example'}>
                <Button as='a' variant='outlined' style={{ margin: '0 auto' }}>
                  {intl.get('product3.example.button')}
                </Button>
              </Link>
            </ButtonContainer>
          </Wrapper>
        </ExampleSection>
        <Wrapper style={{ paddingTop: '68px', paddingBottom: '68px' }}>
          <CommonTitle>{intl.get('app.document.title')}</CommonTitle>
          <DocumentCard type='product3' />
        </Wrapper>
        <Swiper>
          <InviteBanner type='product3' />
        </Swiper>
      </Layout>
    )
  }
}

export default ProductSDK
