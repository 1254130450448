import React from 'react'
import intl from 'react-intl-universal'
import { TableContainer } from './atoms'
class Table extends React.PureComponent {
  render() {
    const { type } = this.props
    return (
      <TableContainer>
        <table border='1' cellspacing='0'>
          <tr>
            <th>{intl.get(`${type}.table.th1`)}</th>
            <th>{intl.get(`${type}.table.th2`)}</th>
            <th>{intl.get(`${type}.table.th3`)}</th>
          </tr>
          <tr>
            <td>{intl.get(`${type}.table.td1`)}</td>
            <td>{intl.get(`${type}.table.td2`)}</td>
            <td>{intl.get(`${type}.table.td3`)}</td>
          </tr>
          <tr>
            <td>{intl.get(`${type}.table.td4`)}</td>
            <td>{intl.get(`${type}.table.td5`)}</td>
            <td>{intl.get(`${type}.table.td6`)}</td>
          </tr>
        </table>
      </TableContainer>
    )
  }
}

export default Table
